import React, { useState, useCallback, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/20/solid'
import { MSearchInput } from '../molecules/MSearchInput.js'
import { MEmptyContentPlaceholder } from '../molecules/MEmptyContentPlaceholder'

export const OUsersList = ({
  users,
  onItemClick,
  withPoints,
  className,
  allowedColumns = {},
  emptyListPlaceholder = 'No users assigned',
}) => {
  const [visibleUsers, setVisibleUsers] = useState(users)

  const allowedColumnsFull = useMemo(
    () => ({
      name: true,
      email: true,
      invitation: true,
      active: true,
      points: allowedColumns.points != undefined ? allowedColumns.points : withPoints, // handle legacy withPoints prop
      ...allowedColumns,
    }),
    [allowedColumns, withPoints]
  )

  useEffect(() => {
    if (!visibleUsers && users.length) {
      setVisibleUsers(users)
    }
  }, [users, visibleUsers])

  const handleSearch = useCallback(searchUsers => {
    setVisibleUsers(searchUsers)
  }, [])

  return (
    <div className="react-web--admin__container">
      {!!users?.length && (
        <MSearchInput
          items={users}
          onSearch={handleSearch}
          searchByKeys={['firstName', 'lastName', 'email']}
          className="mb-6"
        />
      )}

      {!!visibleUsers?.length ? (
        <div className="ring-1 ring-gray-300 rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {allowedColumnsFull.name && (
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                  >
                    Name
                  </th>
                )}
                {allowedColumnsFull.email && (
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell uppercase"
                  >
                    Email
                  </th>
                )}
                {allowedColumnsFull.invitation && (
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell uppercase"
                  >
                    Invitation accepted
                  </th>
                )}
                {allowedColumnsFull.active && (
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell uppercase"
                  >
                    Active
                  </th>
                )}

                {allowedColumnsFull.points && (
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell uppercase"
                  >
                    Points
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {visibleUsers.map((user, index) => (
                <tr
                  key={user.id || user.email}
                  className={classNames({
                    'cursor-pointer': !!onItemClick,
                  })}
                  onClick={() => {
                    if (onItemClick) {
                      onItemClick(user)
                    }
                  }}
                >
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-transparent',
                      'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                    )}
                  >
                    {allowedColumnsFull.name && (
                      <div className="font-medium text-gray-900">
                        {user.firstName} {user.lastName}
                      </div>
                    )}
                    <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
                      {allowedColumnsFull.email && <span>{user.email}</span>}
                      {allowedColumnsFull.points && <span>Points: {user.points || 0}</span>}
                      {allowedColumnsFull.invitation && (
                        <span className="flex sm:hidden">
                          Invitation accepted:{' '}
                          {user.didJoin ? (
                            <CheckCircleIcon
                              className={classnames(
                                'ml-1 h-5 w-5 text-active transition ease-in duration-100'
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <MinusCircleIcon
                              className={classnames(
                                'ml-1 h-5 w-5 text-gray-500 transition ease-in duration-100'
                              )}
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      )}
                      {allowedColumnsFull.active && (
                        <span className="flex sm:hidden">
                          Active:{' '}
                          {user.active !== false ? (
                            <CheckCircleIcon
                              className={classnames(
                                'ml-1 h-5 w-5 text-active transition ease-in duration-100'
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <MinusCircleIcon
                              className={classnames(
                                'ml-1 h-5 w-5 text-gray-500 transition ease-in duration-100'
                              )}
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      )}
                    </div>
                    {index !== 0 ? (
                      <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                    ) : null}
                  </td>
                  {allowedColumnsFull.email && (
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {user.email}
                    </td>
                  )}
                  {allowedColumnsFull.invitation && (
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell'
                      )}
                    >
                      <div>
                        {user.didJoin ? (
                          <CheckCircleIcon
                            className={classnames(
                              'h-5 w-5 text-active transition ease-in duration-100'
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <MinusCircleIcon
                            className={classnames(
                              'h-5 w-5 text-gray-500 transition ease-in duration-100'
                            )}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </td>
                  )}
                  {allowedColumnsFull.active && (
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 sm:table-cell'
                      )}
                    >
                      <div>
                        {user.active !== false ? (
                          <CheckCircleIcon
                            className={classnames(
                              'h-5 w-5 text-active transition ease-in duration-100'
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <MinusCircleIcon
                            className={classnames(
                              'h-5 w-5 text-gray-500 transition ease-in duration-100'
                            )}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </td>
                  )}

                  {allowedColumnsFull.points && (
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {user.points || 0}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-12">
          <MEmptyContentPlaceholder>{emptyListPlaceholder}</MEmptyContentPlaceholder>
        </div>
      )}
    </div>
  )
}

OUsersList.propTypes = {
  users: PropTypes.array,
  isAdminList: PropTypes.bool,
  onItemClick: PropTypes.func,
  className: PropTypes.string,
  allowedColumns: PropTypes.object,
  emptyListPlaceholder: PropTypes.string,
}
