import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { AFormInputWrapper } from './AFormInputWrapper.js'

export const AFormTextArea = React.forwardRef(
  ({ label, id, name, disabled, className, errorMessage, ...props }, ref) => {
    return (
      <div className="react-web--admin__container">
        <AFormInputWrapper label={label} id={id} name={name} errorMessage={errorMessage}>
          <textarea
            ref={ref}
            id={id}
            name={name}
            disabled={disabled}
            className={classnames(
              'resize-none overflow-hidden appearance-none block  px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-white disabled:text-gray-500',
              { [className]: true }
            )}
            {...props}
          ></textarea>
        </AFormInputWrapper>
      </div>
    )
  }
)

AFormTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
