import {
  initFirebase,
  firestoreDb,
  functions,
  auth,
  doesUserExistFBFunction,
  doesAdminUserExistsFbFunction,
  doesOrganizationExistsFbFunction,
  doesTeamExistsFbFunction,
  retrieveChatCredentials,
  retrieveTeamLeaderboards,
  createUserFunction,
  updateUserFunction,
  archiveUserFunction,
  archiveTeamFunction,
  updateOrganizationsFunction,
  usersCollectionRef,
  organizationsCollectionRef,
  teamsCollectionRef,
  updateAdminUserMetadata,
  userQuestionnairesCollectionRef,
  adminUsersCollectionRef,
  COLLECTIONS,
} from '@edwin/sdk-admin'

import CONFIG from '@config/Config'

const firebaseApp = initFirebase({ config: CONFIG })

export {
  firebaseApp,
  COLLECTIONS,
  firestoreDb,
  functions,
  auth,
  doesUserExistFBFunction,
  doesAdminUserExistsFbFunction,
  doesOrganizationExistsFbFunction,
  doesTeamExistsFbFunction,
  retrieveChatCredentials,
  retrieveTeamLeaderboards,
  createUserFunction,
  updateUserFunction,
  archiveUserFunction,
  archiveTeamFunction,
  updateOrganizationsFunction,
  usersCollectionRef,
  organizationsCollectionRef,
  teamsCollectionRef,
  userQuestionnairesCollectionRef,
  adminUsersCollectionRef,
  updateAdminUserMetadata,
}
