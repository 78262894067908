import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { BuildingOfficeIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { format } from 'date-fns'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'

import { CSS } from '@dnd-kit/utilities'

import { AButton, MCard, MAccordionItem } from '@edwin/react-web-admin'

const SortableOrganizationListItem = ({ organization, onClick, bottomElement }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: organization.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <li ref={setNodeRef} style={style} {...attributes} {...listeners} className="col-span-1">
      <div style={{ userSelect: 'none' }}>
        <MCard
          className="bg-white"
          title={organization.name}
          isActive
          subtitle={
            organization.createdAt ? `Added ${format(organization.createdAt, 'MMMM yyyy')}` : null
          }
          icon={<BuildingOfficeIcon />}
          iconBg="bg-organization-bg"
          items={[
            {
              label: 'Teams',
              value: organization.teams.length,
            },
            {
              label: 'Active Users',
              value: organization.users.length,
            },
            {
              label: 'Pending Invitations',
              value: organization.invitations,
            },
          ]}
          bottomElement={bottomElement}
          onClick={() => onClick(organization)}
        />
      </div>
    </li>
  )
}

const OPriotizedOrganizations = ({ organizations, onOrganizationClick = () => {}, onReorder }) => {
  const [activeOrganizations, setActiveOrganizations] = useState([])
  const [inactiveOrganizations, setInactiveOrganizations] = useState([])
  const [draggableOrganizations, setDraggableOrganizations] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const isOrganizationsSplit = useRef(false)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const onReorderStart = useCallback(() => {
    setIsDragging(true)
  }, [])

  const onReoderEnd = useCallback(
    (active, inactive) => {
      const organizationsMerged = [...active, ...inactive].map((org, index) => ({
        ...org,
        priority: index,
      }))

      onReorder({
        activeOrganizations: active,
        inactiveOrganizations: inactive,
        organizations: organizationsMerged,
      })
    },
    [onReorder]
  )

  const handleActiveOrganizationsDragEnd = useCallback(
    event => {
      const { active, over } = event

      if (active?.id !== over?.id) {
        const oldIndex = activeOrganizations?.findIndex(item => item.id === active.id)
        const newIndex = activeOrganizations?.findIndex(item => item.id === over.id)

        const newActiveOrganizations = arrayMove(activeOrganizations, oldIndex, newIndex)
        setActiveOrganizations(newActiveOrganizations)

        onReoderEnd(newActiveOrganizations, inactiveOrganizations)
      }

      setIsDragging(false)
    },
    [onReoderEnd, activeOrganizations, inactiveOrganizations]
  )

  const handleInactiveActiveOrganizationsDragEnd = useCallback(
    event => {
      const { active, over } = event

      if (active.id !== over.id) {
        const oldIndex = inactiveOrganizations?.findIndex(item => item.id === active.id)
        const newIndex = inactiveOrganizations?.findIndex(item => item.id === over.id)

        const newInactiveOrganizations = arrayMove(inactiveOrganizations, oldIndex, newIndex)
        setInactiveOrganizations(newInactiveOrganizations)

        onReoderEnd(activeOrganizations, newInactiveOrganizations)
      }

      setIsDragging(false)
    },
    [onReoderEnd, activeOrganizations, inactiveOrganizations]
  )

  const onMakeOrgInactive = useCallback(
    organization => {
      const itemIndex = activeOrganizations.findIndex(item => item.id === organization.id)
      const active = [...activeOrganizations]
      const inactive = [...inactiveOrganizations]

      const [itemToMove] = active.splice(itemIndex, 1)

      itemToMove.priority = inactive.length
      itemToMove.isActive = false

      inactive.push(itemToMove)

      setActiveOrganizations(active)
      setInactiveOrganizations(inactive)

      onReoderEnd(active, inactive)
    },
    [activeOrganizations, inactiveOrganizations, onReoderEnd]
  )

  const onMakeOrgActive = useCallback(
    organization => {
      const itemIndex = inactiveOrganizations.findIndex(item => item.id === organization.id)
      const active = [...activeOrganizations]
      const inactive = [...inactiveOrganizations]

      const [itemToMove] = inactive.splice(itemIndex, 1)

      itemToMove.priority = active.length
      itemToMove.isActive = true

      active.push(itemToMove)

      setActiveOrganizations(active)
      setInactiveOrganizations(inactive)

      onReoderEnd(active, inactive)
    },
    [activeOrganizations, inactiveOrganizations, onReoderEnd]
  )

  useEffect(() => {
    if (!isOrganizationsSplit.current) {
      const active = organizations
        .filter(org => org.isActive !== false)
        .sort((a, b) => a.priority - b.priority)
      const inactive = organizations
        .filter(org => org.isActive === false)
        .sort((a, b) => a.priority - b.priority)

      setActiveOrganizations(active)
      setInactiveOrganizations(inactive)

      isOrganizationsSplit.current = true
    }
  }, [organizations])

  return (
    <div className="space-y-8">
      {!!activeOrganizations?.length && (
        <div className="flex flex-col space-y-4">
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Active Organizations
            </h3>
          </div>

          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={onReorderStart}
              onDragEnd={handleActiveOrganizationsDragEnd}
              modifiers={[restrictToWindowEdges]}
            >
              <SortableContext
                items={activeOrganizations.map(org => org.id.toString())}
                strategy={rectSortingStrategy}
              >
                {activeOrganizations?.map((org, index) => (
                  <SortableOrganizationListItem
                    organization={org}
                    key={org.id}
                    onClick={onOrganizationClick}
                    onArchive={SortableOrganizationListItem}
                    bottomElement={
                      <div className="flex flex-row justify-end">
                        <div>
                          <AButton onClick={() => onMakeOrgInactive(org)}>
                            <div className="flex flex-row">
                              <MinusCircleIcon className="mr-3 w-5 h-5 text-white" />

                              <span>Make inactive</span>
                            </div>
                          </AButton>
                        </div>
                      </div>
                    }
                  />
                ))}
              </SortableContext>
            </DndContext>
          </ul>
        </div>
      )}

      {!!inactiveOrganizations?.length && (
        <MAccordionItem
          className="flex flex-col space-y-4"
          isExpanded={true}
          overflowDisabled={isDragging}
          titleElement={
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Inactive Organizations
              </h3>
            </div>
          }
          bodyElement={
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 py-0.5">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={onReorderStart}
                onDragEnd={handleInactiveActiveOrganizationsDragEnd}
                modifiers={[restrictToWindowEdges]}
              >
                <SortableContext
                  items={inactiveOrganizations.map(org => org.id.toString())}
                  strategy={rectSortingStrategy}
                >
                  {inactiveOrganizations?.map((org, index) => (
                    <SortableOrganizationListItem
                      organization={org}
                      key={org.id}
                      onClick={onOrganizationClick}
                      bottomElement={
                        <div className="flex flex-row justify-end">
                          <div>
                            <AButton onClick={() => onMakeOrgActive(org)}>
                              <div className="flex flex-row">
                                <PlusCircleIcon className="mr-3 w-5 h-5 text-white" />

                                <span>Make active</span>
                              </div>
                            </AButton>
                          </div>
                        </div>
                      }
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </ul>
          }
        />
      )}
    </div>
  )
}

OPriotizedOrganizations.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      createdAt: PropTypes.instanceOf(Date),
      teams: PropTypes.array,
      users: PropTypes.array,
      invitations: PropTypes.number,
    })
  ),
  onOrganizationClick: PropTypes.func,
}

export default OPriotizedOrganizations
