import React, { useState, useCallback, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { AButton, AFormWrapper, MFormInput, OAddUserAssignContent } from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'

import { useOrganization } from '@services/organizations'
import { useTeam } from '@services/teams'
import { addUserWithoutContent } from '@services/users'
import { getDefaultOrganizationContentToAssign } from '@services/content'

import ROUTES, { generateRoute } from '@const/Routes'

const PAddUser = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { organizationId, teamId } = useParams()
  const [organization = location?.state.organization, isOrganizationLoading] =
    useOrganization(organizationId)
  const [team = location?.state.team, isTeamLoading] = useTeam(teamId)
  const [userData, setUserData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [error, setError] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [organizationContent, setOrganizationContent] = useState()
  const [defaultAssignedTopics, setDefaultAssignedTopics] = useState()
  const [isOrganizationContentLoading, setIsOrganizationContentLoading] = useState(true)
  const [defaultAssignedOnboardingMission, setDefaultAssignedOnboardingMission] = useState()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: userData,
  })

  const isLoading = isOrganizationLoading || isTeamLoading || isOrganizationContentLoading

  const breadcrumbs = [
    { type: 'home', navigateTo: ROUTES.ROOT },
    {
      name: organization?.name,
      navigateTo: generateRoute(ROUTES.ORGANIZATION, { organizationId }),
      state: { organization },
    },
    {
      name: team?.name,
      navigateTo: generateRoute(ROUTES.ORGANIZATION_TEAM, { organizationId, teamId }),
      state: { organization },
    },
    { name: 'Add User' },
  ]

  const [nameField, lastName, emailField] = watch(['firstName', 'lastName', 'email'])

  useEffect(() => {
    setShowConfirmation(false)
  }, [nameField, lastName, emailField])

  useEffect(() => {
    const func = async () => {
      setIsOrganizationContentLoading(true)
      const { content, assignedTopics, assignedOnboardingMission } =
        await getDefaultOrganizationContentToAssign()

      const topics = team?.availableTopics?.map(topic => {
        return assignedTopics.find(assignedTopic => assignedTopic.id === topic.id) || topic
      })

      setOrganizationContent(content)
      setDefaultAssignedTopics(topics)
      setDefaultAssignedOnboardingMission(assignedOnboardingMission)
      setIsOrganizationContentLoading(false)
    }

    func()
  }, [team?.availableTopics])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        navigate(generateRoute(ROUTES.ORGANIZATION_TEAM, { organizationId, teamId }), {
          state: { organization, team },
        })
      }, 2000)
    }
  }, [allDone, reset, organizationId, organization, teamId, team, navigate])

  const handleUserFormSubmit = data => {
    setError(null)
    setUserData(data)
    setShowConfirmation(true)
  }

  const saveUserData = useCallback(async () => {
    setIsSaving(true)


    try {
      await addUserWithoutContent({
        ...userData,
        organizationId,
        teamId,
        onboardingMissionId: defaultAssignedOnboardingMission?.id,
      })

      setIsSaving(false)
      setAllDone(true)
    } catch (err) {
      setError(err?.message)
      setIsSaving(false)
    }
  }, [userData, organizationId, teamId, defaultAssignedOnboardingMission])

  return (
    <OPageWrapper
      contentClassName="flex-grow w-fullflex"
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
    >
      <AFormWrapper title="Add User">
        <form onSubmit={handleSubmit(handleUserFormSubmit)} method="POST">
          <div className="px-4 pt-6 sm:pt-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <MFormInput
                  id="firstName"
                  name="firstName"
                  label="First name"
                  errorMessage={errors?.firstName?.message}
                  disabled={isSaving || allDone}
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <MFormInput
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  disabled={isSaving || allDone}
                  errorMessage={errors?.lastName?.message}
                  {...register('lastName', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <MFormInput
                  id="email"
                  name="email"
                  label="Email address"
                  disabled={isSaving || allDone}
                  errorMessage={errors?.email?.message}
                  {...register('email', {
                    required: 'Invalid email address',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <OAddUserAssignContent
            assignedTopics={defaultAssignedTopics}
            assignedOnboardingMission={defaultAssignedOnboardingMission}
            allContentToAssign={organizationContent}
            isLoading={isLoading}
            ctaCancelReorderLabel="Assign missions"
            allowCustomSaveAssignments={false}
            className="px-4 pb-6 sm:pb-8"
          >
            {({ getAssignedContent }) => (
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <div className="flex flex-col items-end">
                  {allDone && !error ? (
                    <div className="rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-accent">
                      All done{' '}
                      <span role="img" aria-label="confetti">
                        🎉
                      </span>
                    </div>
                  ) : (
                    <>
                      {showConfirmation ? (
                        <div className="flex items-center space-x-4">
                          <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                            Are you sure?
                          </p>
                          <AButton
                            secondary
                            onClick={() => {
                              setShowConfirmation(false)
                            }}
                          >
                            Cancel
                          </AButton>
                          <AButton
                            isLoading={isSaving}
                            className="whitespace-nowrap"
                            onClick={() => saveUserData(getAssignedContent)}
                            loadingText="Adding user..."
                          >
                            Yes, save and invite
                          </AButton>
                        </div>
                      ) : (
                        <AButton type="submit">Save</AButton>
                      )}
                      {!!error && (
                        <span className="pt-1 text-sm leading-5 font-medium rounded-md text-red-500">
                          {error}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </OAddUserAssignContent>
        </form>
      </AFormWrapper>
    </OPageWrapper>
  )
}

export default PAddUser
