import React from 'react'
import { useNavigate } from 'react-router-dom'

import OPageWrapper from '@organisms/OPageWrapper'
import { OUsersList } from '@edwin/react-web-admin'
import { useUsers } from '@services/users'

import ROUTES, { generateRoute } from '@const/Routes'

const PUsers = () => {
  const navigate = useNavigate()
  const [users] = useUsers()

  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.USERS }]

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <OUsersList
        users={users}
        allowedColumns={{ active: false }}
        onItemClick={user => {
          navigate(generateRoute(ROUTES.USER, { userId: user.id }), { state: { user } })
        }}
      />
    </OPageWrapper>
  )
}

export default PUsers
