import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Route, Routes, useParams, useNavigate, useMatch, useLocation } from 'react-router-dom'

import {
  AButton,
  OSlideOver,
  OUsersList,
  OEditTeam,
  MTabs,
  OOrganizationTeamsList,
  OAssignContent,
} from '@edwin/react-web-admin'

import { sortTopicsArray } from '@edwin/sdk-admin'

import OPageWrapper from '@organisms/OPageWrapper'

import { useOrganization } from '@services/organizations'
import {
  useTeam,
  useUsersForTeam,
  updateTeamTopics,
  updateTeamOnboardingMission,
  updateTeam,
  useTeamsForOrganization,
  useArchivedUsersForTeam,
  fetchTeamUsersPoints,
  archiveTeam,
} from '@services/teams'
import { fetchAllContent, getDefaultOrganizationContentToAssign } from '@services/content'
import useStore from '@store/useStore'

import { mapFirebaseMissionAndSerie, sleep } from '@utils/helpers'
import ROUTES, { generateRoute } from '@const/Routes'

import { UserIcon, ArchiveBoxIcon, RectangleGroupIcon } from '@heroicons/react/20/solid'

const POrganizationTeam = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const matchUsersRoute = useMatch(ROUTES.ORGANIZATION_TEAM_USERS)
  const matchArchivedUserRoute = useMatch(ROUTES.ORGANIZATION_TEAM_ARCHIVED_USERS)
  const { organizationId, teamId } = useParams()
  const [organization = location?.state?.organization, isOrganizationLoading] =
    useOrganization(organizationId)
  const [team = location?.state?.team, isTeamLoading] = useTeam(teamId)
  const [users, isUsersLoading] = useUsersForTeam(teamId)
  const [archivedUsers, isArchivedUsersLoading] = useArchivedUsersForTeam(teamId)
  const [usersPoints, setUsersPoints] = useState()
  const [isUsersPointsLoading, setIsUsersPointsLoading] = useState(true)
  const [teamTopics, setTeamTopics] = useState()
  const [teamOnboardingMission, setTeamOnboardingMission] = useState()
  const [fullContent, setFullContent] = useState()
  const [onboardingMissionsContent, setOnboardingMissionsContent] = useState()
  const [isFullContentLoading, setIsFullContentLoading] = useState(false)
  const [isTeamTopicsLoading, setIsTeamTopicsLoading] = useState(false)
  const [isContentSaving, setIsContentSaving] = useState(false)
  const { readOnly, isSuperAdmin } = useStore(state => ({
    readOnly: state.readOnly,
    isSuperAdmin: state.isSuperAdmin,
  }))
  const [isEditTeamSlideOverOpen, setIsEditTeamSlideOverOpen] = useState(false)
  const [isOnboardingMissionLoading, setIsOnboardingMissionLoading] = useState(false)

  const usersMapped = useMemo(() => {
    if (!usersPoints) {
      return users
    }

    return users.map(user => ({
      ...user,
      points: usersPoints?.find(up => up.uid === user.id)?.points,
    }))
  }, [users, usersPoints])

  const teamTotalPoints = useMemo(() => {
    return usersPoints?.reduce((acc, curr) => acc + curr.points, 0) || 0
  }, [usersPoints])

  const tabs = useMemo(
    () => [
      {
        name: 'Playbook',
        id: 'playbook',
        route: generateRoute(ROUTES.ORGANIZATION_TEAM_PLAYBOOK, { organizationId, teamId }),
        icon: RectangleGroupIcon,
      },
      {
        name: 'Users',
        id: 'users',
        route: generateRoute(ROUTES.ORGANIZATION_TEAM_USERS, { organizationId, teamId }),
        icon: UserIcon,
      },
      {
        name: 'Archived Users',
        id: 'archived-users',
        route: generateRoute(ROUTES.ORGANIZATION_TEAM_ARCHIVED_USERS, { organizationId, teamId }),
        icon: ArchiveBoxIcon,
      },
    ],
    [organizationId, teamId]
  )
  const activeTab = useMemo(() => {
    if (matchUsersRoute) {
      return tabs[1].id
    }

    if (matchArchivedUserRoute) {
      return tabs[2].id
    }

    return tabs[0].id
  }, [matchUsersRoute, matchArchivedUserRoute, tabs])

  const [teams] = useTeamsForOrganization(organization?.id)

  const { setMobileSideContent } = useStore(state => ({
    setMobileSideContent: state.setMobileSideContent,
  }))

  const fetchUsersPoints = useCallback(async () => {
    if (teamId) {
      setIsUsersPointsLoading(true)
      const points = await fetchTeamUsersPoints(teamId)

      setUsersPoints(points)
      setIsUsersPointsLoading(false)
    }
  }, [teamId])

  const handleFetchFullContent = useCallback(async () => {
    setIsFullContentLoading(true)

    const content = await fetchAllContent()

    setFullContent(content)

    setOnboardingMissionsContent(content.onboardingMissions)

    setIsFullContentLoading(false)
  }, [])

  const handleFetchTeamContent = useCallback(async () => {
    if (teamId && team?.availableTopics && fullContent) {
      const teamAvailableTopics = team?.availableTopics
      const teamOnboardingMissionId = team?.onboardingMissionId
      const topics = fullContent.topics
      const missions = fullContent.missions
      const series = fullContent.series

      const fullTeamContent = teamAvailableTopics
        ?.map(teamAvailableTopic => {
          const fullContentTopic = topics.find(t => t.id === teamAvailableTopic.id) || {}

          const fullContentTopicMissions = fullContentTopic?.missionsAndSeries || []

          return {
            ...teamAvailableTopic,
            ...fullContentTopic,
            missionsAndSeries: fullContentTopicMissions.map(mission => {
              const availableTopicMission = teamAvailableTopic?.missionsAndSeries?.find(
                tam => tam.id === mission.id
              )

              const isMissionAvailableInTeamTopic = !!availableTopicMission

              let mappedMission = mapFirebaseMissionAndSerie(
                isMissionAvailableInTeamTopic ? availableTopicMission : mission,
                missions,
                series
              )

              if (!isMissionAvailableInTeamTopic) {
                mappedMission.isAssigned = false
              }

              return mappedMission
            }),
          }
        })
        .sort(sortTopicsArray)

      const teamOnboardingMission = fullContent?.onboardingMissions?.find(
        ob => ob.id === teamOnboardingMissionId
      )

      setTeamOnboardingMission(teamOnboardingMission)
      setTeamTopics(fullTeamContent)
      setIsTeamTopicsLoading(false)
    } else {
      setTeamOnboardingMission(null)
      setTeamTopics(null)
      setIsTeamTopicsLoading(false)
    }
  }, [teamId, team?.availableTopics, team?.onboardingMissionId, fullContent])

  const handleSaveContent = useCallback(
    async topics => {
      setIsContentSaving(true)

      await updateTeamTopics(
        teamId,
        users.map(user => user.id),
        topics
      )
      await sleep(1000)

      setIsContentSaving(false)
    },
    [teamId, users]
  )

  const handleSaveOnboardingMission = useCallback(
    async onboardingMission => {
      setIsOnboardingMissionLoading(true)

      await updateTeamOnboardingMission(
        teamId,
        users.map(user => user.id),
        onboardingMission.id
      )

      setTeamOnboardingMission(onboardingMission)

      setIsOnboardingMissionLoading(false)
    },
    [teamId, users]
  )

  const handleOpenEditTeamSlideOver = useCallback(() => {
    setIsEditTeamSlideOverOpen(true)
  }, [])

  const handleCloseEditTeamSlideOver = useCallback(() => {
    setIsEditTeamSlideOverOpen(false)
  }, [])

  const handleArchiveTeamFinished = useCallback(() => {
    navigate(generateRoute(ROUTES.ORGANIZATION, { organizationId: organization.id }), {
      state: { organization },
    })

    handleCloseEditTeamSlideOver()
  }, [handleCloseEditTeamSlideOver, organization, navigate])

  const handleOnAddTeamClick = useCallback(() => {
    navigate(generateRoute(ROUTES.ADD_TEAM, { organizationId: organization.id }), {
      state: { organization },
    })
  }, [navigate, organization])

  const handleOnTeamClick = useCallback(
    team => {
      setMobileSideContent(false)

      navigate(
        generateRoute(
          ROUTES.ORGANIZATION_TEAM,
          {
            organizationId: organization?.id,
            teamId: team?.id,
          },
          {
            state: { organization, team },
          }
        )
      )
    },
    [navigate, organization, setMobileSideContent]
  )

  const isLoading =
    isOrganizationLoading ||
    isTeamLoading ||
    isUsersLoading ||
    isFullContentLoading ||
    isTeamTopicsLoading ||
    !team ||
    !organization

  const breadcrumbs = [
    { type: 'home', navigateTo: ROUTES.ROOT },
    {
      name: organization?.name,
      navigateTo: generateRoute(ROUTES.ORGANIZATION, { organizationId }),
      state: { organization },
    },
    { name: team?.name },
  ]

  useEffect(() => {
    if (teamId) {
      handleFetchFullContent()
    }
  }, [handleFetchFullContent, teamId])

  useEffect(() => {
    if (teamId) {
      handleFetchTeamContent()
    }
  }, [handleFetchTeamContent, teamId])

  useEffect(() => {
    if (teamId) {
      fetchUsersPoints()
    }
  }, [fetchUsersPoints, teamId])

  return (
    <OPageWrapper
      sideContent={
        <OOrganizationTeamsList
          label="Team"
          activeTeamId={teamId}
          teams={teams}
          onAddTeamClick={handleOnAddTeamClick}
          onTeamClick={handleOnTeamClick}
          readOnly={readOnly}
          isSearchable
          key="team-details-search"
        />
      }
      shouldExpandMobileSideContent={false}
      isLoading={isLoading || isUsersPointsLoading}
      breadcrumbs={breadcrumbs}
    >
      {!!team && !!organization && (
        <>
          <>
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold leading-6 text-gray-900">{team?.name}</h1>
                {/* {!isUsersPointsLoading && (
                  <div className="mt-1.5 text-gray-500">
                    <span className="text-lg">{teamTotalPoints}</span>
                    <span className="text-md"> points</span>
                  </div>
                )} */}
              </div>
              <div className="inline-flex mt-4 sm:ml-16 sm:mt-0">
                {!readOnly && <AButton onClick={handleOpenEditTeamSlideOver}>Edit team</AButton>}
                {isSuperAdmin ? (
                  <AButton
                    className="ml-2 w-full"
                    onClick={() => {
                      navigate(generateRoute(ROUTES.ADD_TEAM_USER, { organizationId, teamId }), {
                        state: { organization, team },
                      })
                    }}
                  >
                    Add user
                  </AButton>
                ) : null}
              </div>
            </div>
            <MTabs
              onTabChange={newTab => navigate(newTab.id)}
              tabs={tabs}
              activeTab={activeTab}
              className="mt-4"
            />
            <div className="mt-10 h-full">
              <Routes>
                {['/', '/playbook'].map(path => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <OAssignContent
                        key={team?.id}
                        assignedTopics={teamTopics}
                        assignedOnboardingMission={teamOnboardingMission}
                        allContentToAssign={fullContent}
                        onSaveOnboardingMission={handleSaveOnboardingMission}
                        isOnboardingMissionLoading={isOnboardingMissionLoading}
                        isLoading={isContentSaving}
                        onSave={handleSaveContent}
                        viewOnly={readOnly}
                        points={teamTotalPoints}
                        onboardingMissions={onboardingMissionsContent}
                        withOnboarding
                      />
                    }
                  />
                ))}

                <Route
                  path={'/users'}
                  element={
                    <OUsersList
                      key={team?.id}
                      users={usersMapped}
                      allowedColumns={{ active: false, withPoints: true }}
                      withPoints
                      onItemClick={user => {
                        navigate(
                          generateRoute(ROUTES.TEAM_USER, {
                            organizationId,
                            teamId,
                            userId: user.id,
                          }),
                          {
                            state: { organization, team, user },
                          }
                        )
                      }}
                    />
                  }
                />

                <Route
                  path={'/archived-users'}
                  element={
                    <OUsersList
                      key={`archived-user-${team?.id}`}
                      allowedColumns={{ active: false, withPoints: false }}
                      users={archivedUsers}
                      emptyListPlaceholder="No users archived"
                    />
                  }
                />
              </Routes>
            </div>
          </>

          <OSlideOver
            slideContent={
              <OEditTeam
                team={team}
                updateTeam={updateTeam}
                onSavingFinished={handleCloseEditTeamSlideOver}
                canArchive={isSuperAdmin}
                archiveTeam={archiveTeam}
                onArchiveFinished={handleArchiveTeamFinished}
                allowedFields={{ leaderboards: false }}
              />
            }
            isOpen={isEditTeamSlideOverOpen}
            onClose={handleCloseEditTeamSlideOver}
          ></OSlideOver>
        </>
      )}
    </OPageWrapper>
  )
}

export default POrganizationTeam
