import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const MCard = ({
  title,
  subtitle,
  icon,
  iconBg,
  items,
  isActive,
  className,
  onClick,
  ctaElement,
  bottomElement,
}) => (
  <div className="react-web--admin__container">
    <div
      onClick={onClick}
      className={classNames(
        'rounded-xl border overflow-hidden',
        isActive ? 'border-accent shadow-md' : 'border-gray-200',
        {
          [className]: true,
          'cursor-pointer': !!onClick,
        }
      )}
    >
      <div
        className={classNames('flex justify-between bg-gray-50 p-6', {
          'border-b border-gray-900 border-opacity-5': items?.length,
        })}
      >
        <div className="flex items-center gap-x-4">
          <div
            className={classNames(
              'flex justify-center items-center h-16 w-16 flex-none rounded-lg object-cover ring-1 ring-gray-900/10',
              iconBg || 'bg-white'
            )}
          >
            {!!icon &&
              React.cloneElement(icon, {
                className: classNames(
                  'w-10 h-10 text-[32px] leading-[1.15] text-center',
                  iconBg ? 'text-gray-50' : 'text-gray-600'
                ),
              })}
          </div>
          <div>
            {title && <div className="text-md font-medium leading-6 text-gray-900">{title}</div>}
            {subtitle && <div className="text-sm leading-6 text-gray-500">{subtitle}</div>}
          </div>
        </div>
        {!!ctaElement && ctaElement}
      </div>
      {items?.length && (
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          {items?.map(({ label, value, isFullWidth }) => {
            if (isFullWidth) {
              return null
            }

            return (
              <div key={`${label}-${value}`} className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">{label}</dt>
                <dd className="text-gray-700 text-right">{value}</dd>
              </div>
            )
          })}
        </dl>
      )}

      {!!bottomElement && <div className="w-full border-t p-4"> {bottomElement}</div>}
    </div>
  </div>
)

MCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  iconBg: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isFullWidth: PropTypes.bool,
    })
  ),
  className: PropTypes.string,
  onClick: PropTypes.func,
  ctaElement: PropTypes.node,
  bottomElement: PropTypes.node,
}
