import React, { useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { MFormInput } from '../molecules/MFormInput'
import { MFormSelect } from '../molecules/MFormSelect'
import { AButton } from '../atoms/AButton'

export const OEditTeam = ({
  team,
  onSavingFinished = () => {},
  onArchiveFinished = () => {},
  updateTeam = () => {},
  archiveTeam = () => {},
  allowedFields = {},
  canArchive,
  isLoading,
}) => {
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [editError, setEditError] = useState()
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)
  const [isArchiveLoading, setIsArchiveLoading] = useState(false)
  const [archiveError, setArchiveError] = useState()

  const allowedFieldsFull = useMemo(
    () => ({
      name: true,
      leaderboards: true,
      ...allowedFields,
    }),
    [allowedFields]
  )

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...team },
  })

  const handleSubmitEdit = async data => {
    setEditError(null)
    setIsEditSaving(true)
    try {
      await updateTeam(data)
      await onSavingFinished()
    } catch (err) {
      setEditError(err?.message)
    }

    setIsEditSaving(false)
  }

  const handleArchiveTeam = async () => {
    setArchiveError(null)
    setIsArchiveLoading(true)
    try {
      await archiveTeam(team.id)
      await onArchiveFinished()
    } catch (err) {
      setArchiveError(err?.message)
    }
  }

  return (
    <div className="react-web--admin__container" style={{ height: '100%' }}>
      <div className="flex flex-col justify-between h-full flex-grow">
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <div className="grid max-w-2xl grid-cols-1 gap-y-4">
            {allowedFieldsFull.name && (
              <div>
                <MFormInput
                  id="name"
                  type="text"
                  name="name"
                  label="Name"
                  errorMessage={errors?.name?.message}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
            )}
            {allowedFieldsFull.leaderboards && (
              <div>
                <Controller
                  name="isLeaderboardAvailable"
                  register={register('isLeaderboardAvailable')}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    const values = {
                      allowed: { name: 'Allowed', id: 'allowed' },
                      notAllowed: { name: 'Not allowed', id: 'not-allowed' },
                    }

                    return (
                      <MFormSelect
                        label="Leaderboards"
                        items={[values.allowed, values.notAllowed]}
                        className="w-1/2"
                        defaultValue={value ? values.allowed : values.notAllowed}
                        onChange={value => {
                          onChange(value?.id === 'allowed')
                        }}
                      />
                    )
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col mt-6">
            <div className="flex flex-col items-center">
              <AButton
                type="submit"
                className="w-full"
                isLoading={isEditSaving}
                loadingText="Saving changes..."
              >
                Save changes
              </AButton>

              {!!editError && (
                <div className="text-sm leading-5 mt-2 font-medium text-red-500">{editError}</div>
              )}
            </div>
          </div>
        </form>

        <div className="py-4">
          {canArchive && (
            <div className="flex flex-col items-center">
              {showArchiveConfirmation ? (
                <div className="flex items-center space-x-4">
                  <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                    Are you sure?
                  </p>
                  <AButton
                    secondary
                    onClick={() => {
                      setShowArchiveConfirmation(false)
                    }}
                  >
                    Cancel
                  </AButton>
                  <AButton
                    warning
                    isLoading={isArchiveLoading}
                    onClick={handleArchiveTeam}
                    loadingText="Archiving..."
                    className="whitespace-nowrap"
                    loadingText="Archiving..."
                  >
                    Yes, archive this team
                  </AButton>
                </div>
              ) : (
                <AButton
                  secondary
                  className="w-full"
                  onClick={() => {
                    setShowArchiveConfirmation(true)
                  }}
                >
                  Archive team
                </AButton>
              )}

              {!!archiveError && (
                <div className="text-sm leading-5 mt-2 font-medium text-red-500">{editError}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

OEditTeam.propTypes = {
  team: PropTypes.object.isRequired,
  onSavingFinished: PropTypes.func,
  allowedFields: PropTypes.object,
  isLoading: PropTypes.bool,
  canArchive: PropTypes.bool,
}
