import React, { useCallback, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { isArray } from 'lodash'

import { OError, OLoader } from '@edwin/react-web-admin'

import PSignIn from '@pages/PSignIn'
import PSignInWithEmailLink from '@pages/PSignInWithEmailLink'
import POrganizations from '@pages/POrganizations'
import POrganizationDetails from '@pages/POrganizationDetails'
import POrganizationTeam from '@pages/POrganizationTeam'
import PAdminUser from '@pages/PAdminUser'
import PAdminUsers from '@pages/PAdminUsers'
import PAddAdminUser from '@pages/PAddAdminUser'
import PUsers from '@pages/PUsers'
import PUser from '@pages/PUser'
import PAddOrganization from '@pages/PAddOrganization'
import PAddOrganizationTeam from '@pages/PAddOrganizationTeam'
import PAddTeamUser from '@pages/PAddTeamUser'
import PChat from '@pages/PChat'
import useAuthClaims from '@hooks/useAuthClaims'
import { auth, updateAdminUserMetadata } from '@services/firebase'
import useStore from '@store/useStore'
import useChatsStore from '@store/useChatStore'
import ROUTES from '@const/Routes'

//initialize
import '@services/firebase'
import '@services/axios'

import { fetchChatCredentials } from '@services/chat'

const App = () => {
  const [claims, isAuthClaimsLoading, authClaimsError] = useAuthClaims()
  const [authUser, isAuthUserloading, authUserError] = useAuthState(auth)

  const {
    archivedUsers,
    loadAdminUser,
    setAdminAccess,
    loadOrganizations,
    loadTeams,
    loadUsers,
    loadArchivedUsers,
    loadAdminUsers,
    loadAcl,
    isTeamsLoading,
    isOrganizationsLoading,
    isUsersLoading,
    isAdminUsersLoading,
    isAclLoading,
    isSuperAdmin,
    isTeacherAdmin,
    cleanStore,
    subscribeMobileDetectionListener,
    isMobile,
  } = useStore(state => ({
    archivedUsers: state.archivedUsers,
    loadAdminUser: state.loadAdminUser,
    setAdminAccess: state.setAdminAccess,
    loadOrganizations: state.loadOrganizations,
    loadTeams: state.loadTeams,
    loadUsers: state.loadUsers,
    loadArchivedUsers: state.loadArchivedUsers,
    loadAdminUsers: state.loadAdminUsers,
    loadAcl: state.loadAcl,
    isTeamsLoading: state.isTeamsLoading,
    isOrganizationsLoading: state.isOrganizationsLoading,
    isUsersLoading: state.isUsersLoading,
    isAdminUsersLoading: state.isAdminUsersLoading,
    isAclLoading: state.isAclLoading,
    isSuperAdmin: state.isSuperAdmin,
    isTeacherAdmin: state.isTeacherAdmin,
    cleanStore: state.cleanStore,
    subscribeMobileDetectionListener: state.subscribeMobileDetectionListener,
    isMobile: state.isMobile,
  }))

  const { isChatInitialized, initializeChat, subscribeUnreadMessageCount, clearChatStorage } =
    useChatsStore(state => ({
      isChatInitialized: state.isChatInitialized,
      initializeChat: state.initializeChat,
      subscribeUnreadMessageCount: state.subscribeUnreadMessageCount,
      clearChatStorage: state.clearChatStorage,
    }))

  const isCollectionsLoading =
    Boolean(
      isTeamsLoading ||
        isOrganizationsLoading ||
        isUsersLoading ||
        isAdminUsersLoading ||
        isAclLoading
    ) && authUser
  const isLoggedOut = !authUser && !isAuthUserloading
  const isLoading =
    isAuthUserloading || isAuthClaimsLoading || (authUser && !claims) || isCollectionsLoading
  const isError = authUserError || authClaimsError

  const getOrganizationsFromCustomClaims = useCallback(claims => {
    return Array.isArray(claims.acl) ? claims.acl : Object.keys(claims.acl || {})
  }, [])

  const getTeamsFromCustomClaims = useCallback(claims => {
    let teams = []

    if (claims.role !== 'teacher_admin') {
      teams = null // only teacher admin has acl defined in acl
    } else {
      // backwards compatibility for acl being an array ['org1', 'org2']
      if (isArray(claims.acl)) {
        teams = claims.acl.flatMap(acl => acl.teams || [])
      } else {
        // acl is now an object {org1: { team1: true, team2: true}}
        for (const organization in claims.acl) {
          teams = teams.concat(Object.keys(claims.acl[organization]))
        }
      }
    }

    return teams
  }, [])

  const initializeCometChat = useCallback(async () => {
    const { data } = await fetchChatCredentials()

    initializeChat(data)
  }, [initializeChat])

  useEffect(() => {
    if (!isLoggedOut && claims) {
      const shouldFetchAll = claims.role === 'super_admin'
      const organizations = getOrganizationsFromCustomClaims(claims)
      const teams = getTeamsFromCustomClaims(claims)

      loadAdminUser(authUser.uid)
      setAdminAccess(claims)
      loadOrganizations({ organizations, shouldFetchAll })
      loadTeams({ organizations, teams })
      loadUsers({ organizations, teams, shouldFetchAll })
      loadArchivedUsers({ organizations, teams, shouldFetchAll })
      loadAdminUsers(shouldFetchAll)
      loadAcl(shouldFetchAll)

      initializeCometChat().catch(() => {
        console.error('Initialize CometChat error')
      })
    }
  }, [
    authUser?.uid,
    isLoggedOut,
    loadAdminUser,
    setAdminAccess,
    loadOrganizations,
    loadTeams,
    loadUsers,
    loadArchivedUsers,
    claims,
    loadAdminUsers,
    loadAcl,
    getOrganizationsFromCustomClaims,
    getTeamsFromCustomClaims,
    initializeCometChat,
  ])

  useEffect(() => {
    subscribeMobileDetectionListener()
  }, [subscribeMobileDetectionListener])

  useEffect(() => {
    if (isChatInitialized) {
      subscribeUnreadMessageCount()
    }
  }, [isChatInitialized, subscribeUnreadMessageCount])

  useEffect(() => {
    if (isLoggedOut) {
      cleanStore()
      clearChatStorage()
    }
  }, [isLoggedOut, cleanStore, clearChatStorage, setAdminAccess, claims])

  useEffect(() => {
    if (authUser?.uid) {
      updateAdminUserMetadata(authUser.uid)
    }
  }, [authUser?.uid])

  if (isError) {
    return (
      <div className="h-screen flex items-center justify-center">
        <OError />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <OLoader />
      </div>
    )
  }

  if (isLoggedOut) {
    return (
      <Router>
        <Routes>
          <Route exact path={ROUTES.SIGN_IN_WITH_EMAIL_LINK} element={<PSignInWithEmailLink />} />
          <Route path={ROUTES.ROOT} element={<PSignIn />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
    )
  }

  if (isMobile) {
    return (
      <Router>
        <Routes>
          <Route
            path={ROUTES.CHAT}
            element={
              <ProtectedRoute allow={isSuperAdmin}>
                <PChat />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate replace to={ROUTES.CHAT} />} />
        </Routes>
      </Router>
    )
  }

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.SIGN_IN_WITH_EMAIL_LINK} element={<Navigate replace to="/" />} />
        <Route
          path={ROUTES.CHAT}
          element={
            <ProtectedRoute allow={isSuperAdmin || isTeacherAdmin}>
              <PChat />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.ADD_ADMIN_USER}
          element={
            <ProtectedRoute allow={isSuperAdmin}>
              <PAddAdminUser />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_USER}
          element={
            <ProtectedRoute allow={isSuperAdmin}>
              <PAdminUser />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_USERS}
          element={
            <ProtectedRoute allow={isSuperAdmin}>
              <PAdminUsers />
            </ProtectedRoute>
          }
        />

        <Route path={`${ROUTES.USER}/*`} element={<PUser />} />

        <Route path={ROUTES.USERS} element={<PUsers />} />
        <Route
          path={ROUTES.ADD_ORGANIZATION}
          element={
            <ProtectedRoute allow={isSuperAdmin}>
              <PAddOrganization />
            </ProtectedRoute>
          }
        />
        <Route path={ROUTES.ORGANIZATION} element={<POrganizationDetails />} />
        <Route
          path={ROUTES.ADD_TEAM}
          element={
            <ProtectedRoute allow={isSuperAdmin}>
              <PAddOrganizationTeam />
            </ProtectedRoute>
          }
        />
        <Route path={`${ROUTES.ORGANIZATION_TEAM}/*`} exact element={<POrganizationTeam />} />
        <Route path={`${ROUTES.TEAM_USER}/*`} element={<PUser withPoints />} />
        <Route
          path={ROUTES.ADD_TEAM_USER}
          element={
            <ProtectedRoute allow={isSuperAdmin}>
              <PAddTeamUser />
            </ProtectedRoute>
          }
        />
        <Route path={ROUTES.ROOT} exact element={<POrganizations />} />
      </Routes>
    </Router>
  )
}

const ProtectedRoute = ({ allow, children }) => {
  if (!allow) {
    return <Navigate to={ROUTES.ROOT} replace />
  }

  return children
}

export default App
