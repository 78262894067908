import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import useStore from '@store/useStore'
import { useOrganizations } from '@services/organizations'

import ROUTES, { generateRoute } from '@const/Routes'

import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { AButton, MCard } from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'
import OPriotizedOrganizations from '@organisms/OPrioritizedOrganizations'
import OOrganizations from '@organisms/OOrganizations'
import { updateOrganizationsFunction } from '@services/firebase'
import _ from 'lodash'

const POrganizations = () => {
  const isSuperAdmin = useStore(state => state.isSuperAdmin)
  const navigate = useNavigate()
  const [organizations, isOrganizationsLoading] = useOrganizations()
  const [toggleOrganizationReorder, setToggleOrganizationReorder] = useState(false)

  const [renderedOrganizations, setRenderedOrganizations] = useState(organizations)
  const [reorderedOrganizations, setReorderedOrganizations] = useState(organizations)
  const [isReorderLoading, setIsReorderLoading] = useState(false)
  const [isReorderError, setIsReorderError] = useState(false)

  const isSaveReorderDisabled = useMemo(() => {
    if (!reorderedOrganizations?.length) {
      return true
    }

    return _.isEqual(
      renderedOrganizations.map(org => org.id),
      reorderedOrganizations.map(org => org.id)
    )
  }, [renderedOrganizations, reorderedOrganizations])

  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ROOT }]

  const handleNavigateToOrganization = useCallback(
    organization => {
      navigate(generateRoute(ROUTES.ORGANIZATION, { organizationId: organization.id }), {
        state: { organization },
      })
    },
    [navigate]
  )

  const handleCancelOrganizationReorder = useCallback(() => {
    setToggleOrganizationReorder(false)
  }, [])

  const handleOnOrganizationReorder = useCallback(({ organizations }) => {
    setReorderedOrganizations(organizations)
  }, [])

  const handleSaveOrganizationReorder = useCallback(async () => {
    if (reorderedOrganizations) {
      setIsReorderLoading(true)
      setIsReorderError(false)

      try {
        await updateOrganizationsFunction({
          organizations: reorderedOrganizations.map(org => ({
            id: org.id,
            priority: org.priority,
            isActive: !!org.isActive,
          })),
        })

        setRenderedOrganizations(reorderedOrganizations)
      } catch (err) {
        setIsReorderError(true)
      }

      setIsReorderLoading(false)

      setToggleOrganizationReorder(false)
    }
  }, [reorderedOrganizations])

  useEffect(() => {
    if (!renderedOrganizations?.length) {
      setRenderedOrganizations(organizations)
    }
  }, [organizations, renderedOrganizations])

  useEffect(() => {
    setReorderedOrganizations([])
    setIsReorderError(false)
  }, [toggleOrganizationReorder])

  return (
    <OPageWrapper
      breadcrumbs={breadcrumbs}
      buttonElement={
        isSuperAdmin ? (
          <div className="flex flex-row items-center space-x-4">
            {toggleOrganizationReorder ? (
              <>
                <AButton
                  secondary
                  className="whitespace-nowrap"
                  onClick={() => setToggleOrganizationReorder(false)}
                >
                  Cancel reorder
                </AButton>

                <AButton
                  className="whitespace-nowrap"
                  onClick={handleSaveOrganizationReorder}
                  isLoading={isReorderLoading}
                  loadingText="Saving..."
                  disabled={isSaveReorderDisabled}
                >
                  Save reorder
                </AButton>
              </>
            ) : (
              <>
                <AButton
                  secondary
                  className="whitespace-nowrap"
                  onClick={() => setToggleOrganizationReorder(true)}
                >
                  Reorder organizations
                </AButton>

                <AButton
                  className="whitespace-nowrap"
                  onClick={() => navigate(generateRoute(ROUTES.ADD_ORGANIZATION))}
                >
                  Add organization
                </AButton>
              </>
            )}
          </div>
        ) : null
      }
      isLoading={isOrganizationsLoading}
    >
      {toggleOrganizationReorder ? (
        <OPriotizedOrganizations
          organizations={renderedOrganizations}
          onReorder={handleOnOrganizationReorder}
        />
      ) : (
        <OOrganizations
          organizations={renderedOrganizations}
          onOrganizationClick={handleNavigateToOrganization}
        />
      )}
    </OPageWrapper>
  )
}

export default POrganizations
