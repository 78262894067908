import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { format } from 'date-fns'

import { AButton, MCard, MAccordionItem } from '@edwin/react-web-admin'
import { useStore } from '@edwin/sdk-admin'

const OrganizationListItem = ({ organization, onClick }) => {
  return (
    <li className="col-span-1">
      <MCard
        title={organization.name}
        subtitle={
          organization.createdAt ? `Added ${format(organization.createdAt, 'MMMM yyyy')}` : null
        }
        icon={<BuildingOfficeIcon />}
        iconBg="bg-organization-bg"
        items={[
          {
            label: 'Teams',
            value: organization.teams.length,
          },
          {
            label: 'Active Users',
            value: organization.users.length,
          },
          {
            label: 'Pending Invitations',
            value: organization.invitations,
          },
        ]}
        onClick={() => onClick(organization)}
      />
    </li>
  )
}

const OOrganizations = ({ organizations, onOrganizationClick = () => {}, onReorder }) => {
  const [activeOrganizations, setActiveOrganizations] = useState([])
  const [inactiveOrganizations, setInactiveOrganizations] = useState([])
  const isSuperAdmin = useStore(state => state.isSuperAdmin)

  useEffect(() => {
    if (organizations?.length) {
      const active = organizations
        .filter(org => org.isActive !== false)
        .sort((a, b) => a.priority - b.priority)
      const inactive = organizations
        .filter(org => org.isActive === false)
        .sort((a, b) => a.priority - b.priority)

      setActiveOrganizations(active)
      setInactiveOrganizations(inactive)
    }
  }, [organizations])

  if (isSuperAdmin) {
    return (
      <div className="space-y-8">
        {!!activeOrganizations?.length && (
          <div className="flex flex-col space-y-4">
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Active Organizations
              </h3>
            </div>

            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {activeOrganizations?.map(org => (
                <OrganizationListItem
                  organization={org}
                  key={org.id}
                  onClick={onOrganizationClick}
                />
              ))}
            </ul>
          </div>
        )}

        {!!inactiveOrganizations?.length && (
          <MAccordionItem
            className="flex flex-col space-y-4"
            isExpanded={false}
            titleElement={
              <div className="border-b py-5 border-gray-200  sm:flex sm:items-center sm:justify-between">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Inactive Organizations
                </h3>
              </div>
            }
            bodyElement={
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {inactiveOrganizations?.map(org => (
                  <OrganizationListItem
                    organization={org}
                    key={org.id}
                    onClick={onOrganizationClick}
                  />
                ))}
              </ul>
            }
          />
        )}
      </div>
    )
  }

  return (
    <div className="space-y-8">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {organizations?.map(org => (
          <OrganizationListItem organization={org} key={org.id} onClick={onOrganizationClick} />
        ))}
      </ul>
    </div>
  )
}

OOrganizations.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      createdAt: PropTypes.instanceOf(Date),
      teams: PropTypes.array,
      users: PropTypes.array,
      invitations: PropTypes.number,
    })
  ),
  onOrganizationClick: PropTypes.func,
}

export default OOrganizations
