import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import {
  AButton,
  AFormWrapper,
  MFormSelect,
  MFormCheckboxList,
  MFormOrganizationsCheckboxList,
  MFormInput,
  MCommandPalette,
} from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'

import { addAdminUser } from '@services/users'
import { useOrganizations, useTeamsForOrganizations } from '@services/organizations'

import ROUTES, { generateRoute } from '@const/Routes'
import { lte } from 'lodash'

const PAddAdminUser = () => {
  const navigate = useNavigate()
  const [adminUserData, setAdminUserData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [submitError, setSubmitError] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [organizations] = useOrganizations()

  const {
    register,
    watch,
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: {
      adminRole: 'super_admin',
      ...adminUserData,
    },
  })
  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ADMIN_USERS }, { name: 'Add Admin User' }]

  const [nameField, lastNameField, emailField, aclField, aclTeamsField, adminRoleField] = watch([
    'firstName',
    'lastName',
    'email',
    'acl',
    'aclTeams',
    'adminRole',
  ])

  const isOrganizationSelectionAvailable = useMemo(
    () => adminRoleField === 'admin' || adminRoleField === 'teacher_admin',
    [adminRoleField]
  )
  const isMultipleOrganizationSelection = useMemo(
    () => adminRoleField === 'admin',
    [adminRoleField]
  )
  const isTeamSelectionAvailable = useMemo(() => {
    const aclFieldArray = Object.values(aclField || {}).filter(val => val)

    return !!(adminRoleField === 'teacher_admin' && aclFieldArray.length)
  }, [adminRoleField, aclField])

  const selectedOrganizations = useMemo(() => {
    const aclFieldArray = Object.keys(aclField || {})

    if (!aclFieldArray.length) return []

    return aclFieldArray.map(orgId => organizations.find(org => org.id === orgId))
  }, [aclField, organizations])

  const [teams] = useTeamsForOrganizations(selectedOrganizations)

  const selectedTeams = useMemo(() => {
    const aclTeamsArray = Object.keys(aclTeamsField || {})

    if (!aclTeamsArray.length || !teams?.length) return []

    return aclTeamsArray.map(teamId => teams.find(team => team.id === teamId))
  }, [aclTeamsField, teams])

  useEffect(() => {
    setShowConfirmation(false)
  }, [nameField, lastNameField, emailField, aclField, aclTeamsField, adminRoleField])

  useEffect(() => {
    setValue('acl', null)
    setValue('aclTeams', null)
  }, [adminRoleField, setValue])

  useEffect(() => {
    setValue('aclTeams', null)
  }, [aclField, setValue])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        navigate(generateRoute(ROUTES.ADMIN_USERS))
      }, 2000)
    }
  }, [allDone, reset, navigate])

  const handleAdminUserFormSubmit = data => {
    const isSuperAdmin = data.adminRole === 'super_admin'

    if (!isSuperAdmin && !Object.values(data.acl || {}).filter(val => val).length) {
      setError('acl', { type: 'manual', message: 'One or more organizations must be selected' })

      return
    }

    setSubmitError(null)
    setAdminUserData(data)
    setShowConfirmation(true)
  }

  const saveUserData = useCallback(async () => {
    setIsSaving(true)

    try {
      const isSuperAdmin = adminUserData.adminRole === 'super_admin'
      let acl = adminUserData.acl
      const aclTeams = adminUserData.aclTeams
      const aclTeamsArray = Object.keys(aclTeams || {}).filter(val => val)

      if (aclTeamsArray?.length) {
        let aclOrgWithTeams = {}

        teams.forEach(team => {
          // Check if the organizationId is in acl and teamId is in aclTeams
          if (acl[team.organizationId] && aclTeams[team.id]) {
            // If organizationId not in result, add it
            if (!aclOrgWithTeams[team.organizationId]) {
              aclOrgWithTeams[team.organizationId] = {}
            }

            // Add teamId to the organization
            aclOrgWithTeams[team.organizationId][team.id] = true
          }
        })

        acl = aclOrgWithTeams
      }

      delete adminUserData.aclTeams
      await addAdminUser({
        ...adminUserData,
        isSuperAdmin: adminUserData.adminRole === 'super_admin',
        acl,
      })
      setIsSaving(false)
      setAllDone(true)
    } catch (err) {
      setSubmitError(err?.message)
      setIsSaving(false)
    }
  }, [adminUserData, teams])

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <AFormWrapper title="Add Admin User">
        <form onSubmit={handleSubmit(handleAdminUserFormSubmit)} method="POST">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <MFormInput
                  id="firstName"
                  name="firstName"
                  label="First name"
                  errorMessage={errors?.firstName?.message}
                  disabled={isSaving || allDone}
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <MFormInput
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  errorMessage={errors?.lastName?.message}
                  disabled={isSaving || allDone}
                  {...register('lastName', {
                    required: { value: true, message: 'This field is required' },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <MFormInput
                  id="email"
                  name="email"
                  label="Email address"
                  errorMessage={errors?.email?.message}
                  disabled={isSaving || allDone}
                  {...register('email', {
                    required: 'Invalid email address',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <Controller
                  name="adminRole"
                  register={register('adminRole')}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    const values = {
                      super_admin: { name: 'Super Admin', id: 'super_admin' },
                      admin: { name: 'Organization Admin', id: 'admin' },
                      teacher_admin: { name: 'Teacher Admin', id: 'teacher_admin' },
                    }

                    const defaultValue = values[getValues().adminRole]

                    return (
                      <MFormSelect
                        id="role"
                        name="role"
                        label="Role"
                        defaultValue={defaultValue}
                        errorMessage={error?.message}
                        items={[values.super_admin, values.admin, values.teacher_admin]}
                        className="w-1/2 mt-1"
                        onChange={value => {
                          onChange(value?.id)
                        }}
                      />
                    )
                  }}
                />
              </div>

              {isOrganizationSelectionAvailable && (
                <div className="sm:col-span-4">
                  <div className="block text-xl font-medium leading-6 text-gray-900">
                    Organizations Access
                  </div>
                  <div className="mt-2">
                    <Controller
                      name="acl"
                      register={register('acl', {
                        required: {
                          value: true,
                          message: 'One or more organizations must be selected',
                        },
                      })}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <MCommandPalette
                          items={organizations}
                          selectedItems={selectedOrganizations}
                          selectedItemsLabel={
                            isMultipleOrganizationSelection
                              ? 'Selected Organizations'
                              : 'Selected Organization'
                          }
                          availableItemsLabel={
                            isMultipleOrganizationSelection
                              ? 'Available Organizations'
                              : 'Available Organization'
                          }
                          onChange={org => {
                            if (isMultipleOrganizationSelection) {
                              let currentValue = aclField || {}

                              const isSelected = !currentValue[org.id]

                              let changedValue = {
                                ...currentValue,
                              }

                              if (!isSelected && changedValue[org.id]) {
                                delete changedValue[org.id]
                              }

                              if (isSelected) {
                                changedValue[org.id] = true
                              }

                              if (!Object.keys(changedValue).length) {
                                changedValue = null
                              }

                              onChange(changedValue)
                            } else {
                              onChange({ [org.id]: true })
                            }
                          }}
                          searchByKeys={['name']}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              {isTeamSelectionAvailable && (
                <div className="sm:col-span-4">
                  <div className="block text-xl font-medium leading-6 text-gray-900">
                    Teams Access
                  </div>

                  <div className="mt-2">
                    <Controller
                      name="aclTeams"
                      register={register('aclTeams', {
                        required: {
                          value: true,
                          message: 'One or more teams must be selected',
                        },
                      })}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <MCommandPalette
                          items={teams}
                          selectedItems={selectedTeams}
                          selectedItemsLabel="Selected Teams"
                          availableItemsLabel="Available Teams"
                          onChange={team => {
                            let currentValue = aclTeamsField || {}

                            const isSelected = !currentValue[team.id]

                            let changedValue = {
                              ...currentValue,
                            }

                            if (!isSelected && changedValue[team.id]) {
                              delete changedValue[team.id]
                            }

                            if (isSelected) {
                              changedValue[team.id] = true
                            }

                            if (!Object.keys(changedValue).length) {
                              changedValue = null
                            }

                            onChange(changedValue)
                          }}
                          searchByKeys={['name']}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <div className="flex flex-col items-end">
              {allDone && !submitError ? (
                <div className="rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-accent">
                  All done{' '}
                  <span role="img" aria-label="confetti">
                    🎉
                  </span>
                </div>
              ) : (
                <>
                  {showConfirmation ? (
                    <div className="flex items-center space-x-4">
                      <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                        Are you sure?
                      </p>
                      <AButton
                        secondary
                        onClick={() => {
                          setShowConfirmation(false)
                        }}
                      >
                        Cancel
                      </AButton>
                      <AButton
                        isLoading={isSaving}
                        className="whitespace-nowrap"
                        onClick={saveUserData}
                      >
                        Yes, save and invite
                      </AButton>
                    </div>
                  ) : (
                    <AButton type="submit">Save</AButton>
                  )}
                  {!!submitError && (
                    <span className="pt-1 text-sm leading-5 font-medium rounded-md text-red-500">
                      {submitError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </AFormWrapper>
    </OPageWrapper>
  )
}

export default PAddAdminUser
