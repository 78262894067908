import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { LogOut } from 'react-feather'
import { signOut } from 'firebase/auth'
import { auth } from '@services/firebase'

import { AAvatar, MDropdown, OLoader } from '@edwin/react-web-admin'

import useStore from '@store/useStore'
import useChatsStore from '@maxsports/web-app/src/store/useChatStore'
import Logo from '@assets/images/logo.svg'

import ROUTES, { generateRoute } from '@const/Routes'

import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { HomeIcon } from '@heroicons/react/20/solid'

const OPageWrapper = ({
  children,
  sideContent,
  contentClassName = '',
  buttonElement,
  breadcrumbs,
  shouldExpandMobileSideContent,
  isLoading,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const { isSuperAdmin, adminUser, setMobileSideContent } = useStore(state => ({
    isSuperAdmin: state.isSuperAdmin,
    adminUser: state.adminUser,
    isMobileSideContentExpanded: state.isMobileSideContentExpanded,
    setMobileSideContent: state.setMobileSideContent,
  }))
  const chatUnreadMessageCount = useChatsStore(state => state.chatUnreadMessageCount)

  const location = useLocation()

  const navigationRoutes = useMemo(() => {
    let routes = [
      {
        pathname: generateRoute(ROUTES.ROOT),
        name: 'Organizations',
        activePath: '/organizations',
        id: 'organizations',
      },
      { pathname: generateRoute(ROUTES.USERS), name: 'Users', activePath: '/users', id: 'users' },
    ]

    if (isSuperAdmin) {
      routes.push({
        pathname: generateRoute(ROUTES.ADMIN_USERS),
        name: 'Admin Users',
        activePath: '/admin-users',
        id: 'admin_users',
      })
    }

    routes.push({
      pathname: generateRoute(ROUTES.CHAT),
      name: 'Messages',
      activePath: '/chat',
      id: 'chat',
    })

    return routes
  }, [isSuperAdmin])

  const getActiveRoutePath = useCallback(
    route => {
      return location.pathname === route.pathname || location.pathname.startsWith(route.activePath)
    },
    [location]
  )

  useEffect(() => {
    if (shouldExpandMobileSideContent !== undefined) {
      setMobileSideContent(shouldExpandMobileSideContent)
    }
  }, [setMobileSideContent, shouldExpandMobileSideContent])

  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between py-6 px-4 lg:px-8"
          aria-label="Global"
        >
          <div className="flex md:flex-1">
            <Link to={ROUTES.ROOT} className="inline-flex -m-1.5 p-1.5">
              <img className="h-8 w-auto" src={Logo} alt="MaxSports Logo" />
            </Link>
          </div>
          <div className="hidden md:flex md:gap-x-12">
            {navigationRoutes.map(route => {
              const isActive = getActiveRoutePath(route)

              return (
                <Link
                  to={route.pathname}
                  className={classnames('relative text-sm leading-6 text-gray-900', {
                    'font-bold': isActive,
                    'font-semibold': !isActive,
                  })}
                  key={route.pathname}
                >
                  {route.name}
                  {route.name === 'Messages' && chatUnreadMessageCount > 0 && (
                    <span className="absolute -top-3 left-3/4 inline-block ml-4 px-2 text-xs text-white text-center bg-persianRed rounded-full">
                      {chatUnreadMessageCount}
                    </span>
                  )}
                </Link>
              )
            })}
          </div>
          <div className="hidden md:flex md:flex-1 lg:justify-end">
            <MDropdown
              onSelect={item => {
                if (item.id === 'sign-out') {
                  signOut(auth)
                }
              }}
              items={[
                {
                  id: 'full-name',
                  customComponent: (
                    <div className="flex flex-col">
                      <span className="font-semi">
                        {adminUser?.firstName} {adminUser?.lastName}
                      </span>
                      <span className="font-regular text-xs text-gray-900 truncate">
                        {adminUser?.email}
                      </span>
                    </div>
                  ),
                },
                { name: 'Sign out', id: 'sign-out', icon: <LogOut /> },
              ]}
              dropdownClassName="max-w-xs"
            >
              {adminUser && (
                <AAvatar firstName={adminUser?.firstName} lastName={adminUser?.lastName} />
              )}
            </MDropdown>
          </div>
          <div className="flex md:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </nav>
        <Dialog as="div" className="hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-100" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-100 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-200">
            <div className="flex items-center justify-between">
              <Link to={ROUTES.ROOT} className="inline-flex -m-1.5 p-1.5">
                <img className="h-8 w-auto" src={Logo} alt="MaxSports Logo" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-900"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-200">
                <div className="space-y-2 py-6">
                  {navigationRoutes.map(route => {
                    const isActive = getActiveRoutePath(route)

                    return (
                      <Link
                        to={route.pathname}
                        className={classnames('block px-3 py-2 text-base leading-7 text-gray-900', {
                          'font-bold': isActive,
                          'font-semibold': !isActive,
                        })}
                        key={route.pathname}
                      >
                        {route.name}
                      </Link>
                    )
                  })}
                </div>
                <div className="py-1">
                  <div className="flex flex-col py-4 px-4">
                    <span className="font-semi">
                      {adminUser?.firstName} {adminUser?.lastName}
                    </span>
                    <span className="font-regular text-xs text-gray-900">{adminUser?.email}</span>
                  </div>
                  <div
                    onClick={() => signOut(auth)}
                    className="flex flex-row items-center cursor-pointer px-4 py-2 text-base text-gray-900 font-semibold"
                  >
                    <LogOut className="mr-2 w-4 h-4" />
                    <span>Sign out</span>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="mx-auto flex w-full max-w-7xl items-center justify-between p-4 lg:px-8">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
            {!!breadcrumbs &&
              breadcrumbs.map((bc, index) => {
                const isActive = breadcrumbs.length === index + 1

                if (bc.type === 'home') {
                  return (
                    <li key={index} className="flex min-h-[38px]">
                      <div className="flex items-center">
                        <Link to={bc.navigateTo} className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </Link>
                      </div>
                    </li>
                  )
                }

                if (isActive) {
                  return (
                    <li key={bc.name} className="flex">
                      <div className="flex items-center">
                        <svg
                          className="h-full w-6 flex-shrink-0 text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div className="ml-4 text-sm font-medium text-gray-500">{bc.name}</div>
                      </div>
                    </li>
                  )
                }

                return (
                  <li key={bc.name} className="flex">
                    <div className="flex items-center">
                      <svg
                        className="h-full w-6 flex-shrink-0 text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <Link
                        to={bc.navigateTo}
                        state={bc.state}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        {bc.name}
                      </Link>
                    </div>
                  </li>
                )
              })}
          </ol>
        </nav>

        <div>{!!buttonElement && buttonElement}</div>
      </div>

      <div className="mx-auto flex-1 flex items-stretch w-full max-w-7xl items-start gap-x-8 p-4 sm:px-6 lg:px-8">
        {!!sideContent && (
          <aside className="sticky top-0 hidden lg:block max-h-screen w-96 overflow-y-auto shrink-0 py-4 pr-3 border-r border-gray-300">
            {sideContent}
          </aside>
        )}

        <main
          className={classnames('w-full', {
            [contentClassName]: true,
          })}
        >
          {isLoading ? (
            <div className="flex items-center h-full">
              <OLoader className="h-full" />
            </div>
          ) : (
            <div
              className={classnames('flex flex-col h-full', {
                'py-4': !!sideContent,
              })}
            >
              {children}
            </div>
          )}
        </main>
      </div>
    </div>
  )
}

OPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  sideContent: PropTypes.node,
  contentClassName: PropTypes.string,
  buttonElement: PropTypes.node,
  isLoading: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      navigateTo: PropTypes.string,
      type: PropTypes.oneOf(['home', 'link']),
    })
  ),
}

export default OPageWrapper
