import React, { useState, useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { MFormSelect, AFormWrapper, MFormInput, AButton } from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'

import { useOrganization } from '@services/organizations'
import { addTeam } from '@services/teams'
import { fetchAllContent, getDefaultOrganizationContentToAssign } from '@services/content'
import { OAddTeamAssignContent } from '@edwin/react-web-admin'

import ROUTES, { generateRoute } from '@const/Routes'

const PAddOrganizationTeam = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { organizationId } = useParams()
  const [organization = location?.state.organization, isOrganizationLoading] =
    useOrganization(organizationId)

  const [organizationContent, setOrganizationContent] = useState()
  const [isOrganizationContentLoading, setIsOrganizationContentLoading] = useState(false)
  const [onboardingMissionsContent, setOnboardingMissionsContent] = useState()
  const [defaultAssignedTopics, setDefaultAssignedTopics] = useState()
  const [defaultAssignedOnboardingMission, setDefaultAssignedOnboardingMission] = useState()

  const [teamData, setTeamData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [error, setError] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: { ...teamData, isLeaderboardAvailable: true },
  })

  const isLoading = isOrganizationLoading || isOrganizationContentLoading

  const breadcrumbs = [
    { type: 'home', navigateTo: ROUTES.ROOT },
    {
      name: organization?.name,
      navigateTo: generateRoute(ROUTES.ORGANIZATION, { organizationId }),
      state: { organization },
    },
    { name: 'Add Team' },
  ]

  const [nameField, isLeaderboardAvailableField] = watch(['name', 'isLeaderboardAvailable'])

  useEffect(() => {
    setShowConfirmation(false)
  }, [nameField, isLeaderboardAvailableField])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        navigate(generateRoute(ROUTES.ORGANIZATION, { organizationId }), {
          state: { organization },
        })
      }, 4000)
    }
  }, [allDone, navigate, organization, organizationId, reset])

  const handleTeamFormSubmit = data => {
    setError(null)
    setTeamData({ ...data, organizationId })
    setShowConfirmation(true)
  }

  const saveTeamData = useCallback(
    async getAssignedContent => {
      setIsSaving(true)

      try {
        const { topics, onboardingMission } = await getAssignedContent()

        const dataToAdd = {
          ...teamData,
          availableTopics: topics,
        }

        if (onboardingMission?.id) {
          dataToAdd.onboardingMissionId = onboardingMission.id
        }

        await addTeam(dataToAdd)

        setIsSaving(false)
        setAllDone(true)
      } catch (err) {
        setError(err?.message)
        setIsSaving(false)
      }
    },

    [teamData]
  )

  const handleFetchFullContent = useCallback(async () => {
    setIsOrganizationContentLoading(true)

    const { content, assignedTopics, assignedOnboardingMission } =
      await getDefaultOrganizationContentToAssign()

    setOrganizationContent(content)
    setDefaultAssignedTopics(assignedTopics)
    setDefaultAssignedOnboardingMission(assignedOnboardingMission)

    setIsOrganizationContentLoading(false)
  }, [])

  useEffect(() => {
    handleFetchFullContent()
  }, [handleFetchFullContent])

  return (
    <OPageWrapper breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <AFormWrapper title="Add team to organization">
        <form onSubmit={handleSubmit(handleTeamFormSubmit)} method="POST">
          <div className="px-8 py-6 sm:p-8">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <MFormInput
                  id="name"
                  name="name"
                  label="Team name"
                  errorMessage={errors?.name?.message}
                  disabled={isSaving || allDone}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                  labelClassName="block text-xl font-bold leading-6 text-gray-900 mb-4"
                />
              </div>
              {/* <div className="sm:col-span-4">
                <Controller
                  name="isLeaderboardAvailable"
                  register={register('isLeaderboardAvailable')}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    const values = {
                      allowed: { name: 'Yes', id: 'allowed' },
                      notAllowed: { name: `No, don't use for this team`, id: 'not-allowed' },
                    }

                    return (
                      <MFormSelect
                        label="Should this team use a leaderboard?"
                        items={[values.allowed, values.notAllowed]}
                        className="w-1/2 mt-1"
                        defaultValue={value ? values.allowed : values.notAllowed}
                        onChange={value => {
                          onChange(value?.id === 'allowed')
                        }}
                        labelClassName="block text-xl font-bold leading-6 text-gray-900 mb-4"
                      />
                    )
                  }}
                />
              </div> */}
            </div>
          </div>

          <OAddTeamAssignContent
            assignedTopics={defaultAssignedTopics}
            assignedOnboardingMission={defaultAssignedOnboardingMission}
            allContentToAssign={organizationContent}
            isLoading={isLoading}
            ctaCancelReorderLabel="Assign missions"
            allowCustomSaveAssignments={true}
            className="px-8 pb-6 sm:pb-8"
          >
            {({ getAssignedContent }) => (
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <div className="flex flex-col items-end">
                  {allDone && !error ? (
                    <div className="rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-accent">
                      All done{' '}
                      <span role="img" aria-label="confetti">
                        🎉
                      </span>
                    </div>
                  ) : (
                    <>
                      {showConfirmation ? (
                        <div className="flex items-center space-x-4">
                          <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                            Are you sure?
                          </p>
                          <AButton
                            secondary
                            onClick={() => {
                              setShowConfirmation(false)
                            }}
                          >
                            Cancel
                          </AButton>
                          <AButton
                            isLoading={isSaving}
                            className="whitespace-nowrap"
                            onClick={() => saveTeamData(getAssignedContent)}
                            loadingText="Adding team..."
                          >
                            Yes, add new team
                          </AButton>
                        </div>
                      ) : (
                        <AButton type="submit">Save</AButton>
                      )}
                      {!!error && (
                        <span className="pt-1 text-sm leading-5 font-medium rounded-md text-red-500">
                          {error}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </OAddTeamAssignContent>
        </form>
      </AFormWrapper>
    </OPageWrapper>
  )
}

export default PAddOrganizationTeam
