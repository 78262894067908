import { useCallback, useMemo, useState, useEffect, Fragment } from 'react'
import classnames from 'classnames'

import { DateTime } from 'luxon'

import { orderBy, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { MCard } from '../molecules'
import { OLoader } from '../organisms'
import { SquaresPlusIcon } from '@heroicons/react/24/outline'
import 'reactflow/dist/style.css'

import { userQuestionnairesCollectionRef, fetchAllContent } from '@edwin/sdk-admin'

export const OQuestionnaires = ({ user }) => {
  const [activeQuestionnaire, setActiveQuestionnaire] = useState()
  const [missions, setMissions] = useState([])
  const [areMissionsLoading, setAreMissionsLoading] = useState(true)

  const userQuestionnairesCollectionQuery = useMemo(() => {
    return user?.id
      ? query(
          userQuestionnairesCollectionRef,
          where('userId', '==', user.id),
          orderBy('createdOn', 'desc')
        )
      : undefined
  }, [user?.id])
  const [questionnaires, areQuestionnairesLoading] = useCollectionData(
    userQuestionnairesCollectionQuery,
    {
      initialValue: [],
    }
  )

  const handleFetchContent = useCallback(async () => {
    setAreMissionsLoading(true)
    const content = await fetchAllContent()
    setMissions(content?.missions || [])
    setAreMissionsLoading(false)
  }, [])

  useEffect(() => {
    const asyncFn = async () => {
      await handleFetchContent()
    }

    asyncFn()
  }, [handleFetchContent])

  const groupedQuestions = useMemo(() => {
    let groups = {}
    if (!activeQuestionnaire) return groups

    Object.values(activeQuestionnaire.questionnaireAnswers).forEach(item => {
      let groupName = item.groupId
      let nestedGroupName

      if (groupName.includes('---')) {
        ;[groupName, nestedGroupName] = groupName.split(' --- ')

        if (!groups[groupName]) {
          groups[groupName] = { items: [], nestedGroups: {} }
        }

        if (!groups[groupName].nestedGroups[nestedGroupName]) {
          groups[groupName].nestedGroups[nestedGroupName] = []
        }

        groups[groupName].nestedGroups[nestedGroupName].push(item)
      } else {
        if (!groups[groupName]) {
          groups[groupName] = { items: [], nestedGroups: {} }
        }

        groups[groupName].items.push(item)
      }
    })

    // Sort items in each group and nested group by priority
    for (let group in groups) {
      groups[group].items.sort((a, b) => a.priority - b.priority)

      for (let nestedGroup in groups[group].nestedGroups) {
        groups[group].nestedGroups[nestedGroup].sort((a, b) => a.priority - b.priority)
      }
    }

    return groups
  }, [activeQuestionnaire])

  const getMissionNameById = useCallback(
    missionId => {
      return missions.find(({ id }) => id === missionId)?.title
    },
    [missions]
  )

  if (areQuestionnairesLoading) {
    return (
      <div className="react-web--admin__container" style={{ height: '100%' }}>
        <div className="flex items-center h-full">
          <OLoader className="h-full" />
        </div>
      </div>
    )
  }

  return (
    <div className="react-web--admin__container">
      <div className="flex flex-1 min-h-full justify-between flex-wrap">
        <div className="w-96 pr-3">
          <ul className="grid grid-cols-1 gap-y-4">
            {questionnaires.map((questionnaire, index) => (
              <li key={index}>
                <MCard
                  title="Questionnaire results"
                  icon={<SquaresPlusIcon />}
                  onClick={() => {
                    setActiveQuestionnaire(questionnaire)
                  }}
                  className="mb-3"
                  isActive={questionnaire.createdOn === activeQuestionnaire?.createdOn}
                  items={[
                    {
                      label: 'Submitted on',
                      value: DateTime.fromISO(questionnaire.createdOn).toLocaleString(
                        DateTime.DATETIME_SHORT
                      ),
                    },
                    {
                      label: 'Mission',
                      value: getMissionNameById(questionnaire.missionId) || questionnaire.missionId,
                    },
                  ]}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 mt-8 ml-8 rounded-lg">
          {activeQuestionnaire && (
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            Group
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Question
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Answers
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {Object.entries(groupedQuestions).map(([groupName, group], groupIndex) => (
                          <Fragment key={groupName}>
                            <tr className="border-t border-gray-200">
                              <th
                                colSpan={5}
                                scope="colgroup"
                                className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                              >
                                {groupName}
                              </th>
                            </tr>
                            {group.items.map((item, itemIndex) => (
                              <tr
                                key={item.questionLabel}
                                className={classnames(
                                  itemIndex === 0 ? 'border-gray-300' : 'border-gray-200',
                                  'border-t'
                                )}
                              >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"></td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                  {item.questionLabel}
                                </td>
                                {item.answers.map((answer, answerIndex) => (
                                  <td
                                    key={`${answer.id}`}
                                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-3"
                                  >
                                    {answer.label}
                                  </td>
                                ))}
                              </tr>
                            ))}
                            {Object.entries(group.nestedGroups).map(
                              ([nestedGroupName, nestedGroup], nestedGroupIndex) => (
                                <Fragment key={`${groupName}-${nestedGroupName}`}>
                                  <tr className="border-t border-gray-200">
                                    <th
                                      colSpan={5}
                                      scope="colgroup"
                                      className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                    >
                                      {groupName} &rarr; {nestedGroupName}
                                    </th>
                                  </tr>
                                  {nestedGroup.map((item, itemIndex) => (
                                    <tr
                                      key={item.questionLabel}
                                      className={classnames(
                                        itemIndex === 0 ? 'border-gray-300' : 'border-gray-200',
                                        'border-t'
                                      )}
                                    >
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"></td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                        {item.questionLabel}
                                      </td>
                                      {item.answers.map((answer, answerIndex) => (
                                        <td
                                          key={`${answer.id}`}
                                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-3"
                                        >
                                          {answer.label}
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </Fragment>
                              )
                            )}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
