import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import enUS from 'date-fns/locale/en-US'
import classnames from 'classnames'

import { AFormInputWrapper } from '../atoms/AFormInputWrapper.js'

registerLocale('en-US', enUS)

export const MDatePickerInput = ({
  label,
  id,
  name,
  errorMessage,
  placeholder = 'Select a date',
  containerClassName,
  calendarClassName,
  inputClassName,
  date,
  onChange = () => {},
  minDate = DateTime.now(),
}) => {
  const [componentDate, setComponentDate] = useState()
  const [showCalendar, setShowCalendar] = useState(false)
  const datePickerRef = useRef()

  const handleClick = () => {
    setShowCalendar(!showCalendar)
  }

  const handleOutsideClick = event => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowCalendar(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div className="react-web--admin__container">
      <div className="relative" ref={datePickerRef}>
        <AFormInputWrapper label={label} id={id} name={name} errorMessage={errorMessage}>
          <input
            type="text"
            readOnly
            value={date ? date.toLocaleString(DateTime.DATE_SHORT) : ''}
            onClick={handleClick}
            placeholder={placeholder}
            className={classnames(
              'mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-white disabled:text-gray-500',
              {
                [inputClassName]: true,
                'border-gray-300 focus:shadow-outline-gray-300 focus:border-blue-300':
                  !errorMessage,
                'border-red-400': !!errorMessage,
              }
            )}
          />
        </AFormInputWrapper>

        {showCalendar && (
          <div className="origin-top-right absolute right-0 mt-4 max-w-64 rounded-md  z-50">
            <DatePicker
              selected={componentDate}
              onChange={date => {
                setComponentDate(date)
                setShowCalendar(false)
                onChange(DateTime.fromJSDate(date))
              }}
              locale="en-US"
              inline
              calendarClassName="bg-white rounded-md shadow-lg border"
              minDate={minDate?.toJSDate()} // Add this line to handle minDate
            />
          </div>
        )}
      </div>
    </div>
  )
}

MDatePickerInput.propTypes = {
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  calendarClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  date: PropTypes.object, // Luxon DateTime
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.object, // Luxon DateTime
}
