import React, { useState, useCallback } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import {
  AButton,
  OSlideOver,
  MEmptyContentPlaceholder,
  OOrganizationTeamsList,
  OEditOrganization,
} from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'
import { useOrganization, updateOrganization } from '@services/organizations'
import useStore from '@store/useStore'

import { useTeamsForOrganization } from '@services/teams'

import ROUTES, { generateRoute } from '@const/Routes'

const POrganizationDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { organizationId } = useParams()
  const { teamId } = useParams()

  const [organization = location?.state?.organization] = useOrganization(organizationId)
  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ROOT }, { name: organization?.name }]
  const [isEditOrganizationSlideOverOpen, setIsEditOrganizationSlideOverOpen] = useState(false)
  const [teams] = useTeamsForOrganization(organization.id)

  const { readOnly, setMobileSideContent } = useStore(state => ({
    readOnly: state.readOnly,
    setMobileSideContent: state.setMobileSideContent,
  }))

  const handleOpenOrganizationSlideOver = useCallback(() => {
    setIsEditOrganizationSlideOverOpen(true)
  }, [])

  const handleCloseOrganizationSlideOver = useCallback(() => {
    setIsEditOrganizationSlideOverOpen(false)
  }, [])

  const handleOnAddTeamClick = useCallback(() => {
    navigate(generateRoute(ROUTES.ADD_TEAM, { organizationId: organization.id }), {
      state: { organization },
    })
  }, [navigate, organization])

  const handleOnTeamClick = useCallback(
    team => {
      setMobileSideContent(false)

      navigate(
        generateRoute(
          ROUTES.ORGANIZATION_TEAM,
          {
            organizationId: organization?.id,
            teamId: team?.id,
          },
          {
            state: { organization, team },
          }
        )
      )
    },
    [navigate, organization, setMobileSideContent]
  )

  return (
    <OPageWrapper
      sideContent={
        <OOrganizationTeamsList
          label="Team"
          organization={organization}
          activeTeamId={teamId}
          teams={teams}
          onAddTeamClick={handleOnAddTeamClick}
          onTeamClick={handleOnTeamClick}
          readOnly={readOnly}
          isSearchable
          key="organization-details-search"
        />
      }
      shouldExpandMobileSideContent
      buttonElement={
        !readOnly && <AButton onClick={handleOpenOrganizationSlideOver}>Edit Organization</AButton>
      }
      breadcrumbs={breadcrumbs}
    >
      <div className="mt-8">
        <MEmptyContentPlaceholder description="Select a team" />
      </div>

      <OSlideOver
        slideContent={
          !readOnly && (
            <OEditOrganization
              organization={organization}
              onSavingFinished={handleCloseOrganizationSlideOver}
              onUpdateOrganization={updateOrganization}
            />
          )
        }
        isOpen={isEditOrganizationSlideOverOpen}
        onClose={handleCloseOrganizationSlideOver}
      ></OSlideOver>
    </OPageWrapper>
  )
}

export default POrganizationDetails
