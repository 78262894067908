import React, { useCallback, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useStore } from '@edwin/sdk-admin'
import { updateUser, deleteUser, archiveUser } from '@edwin/sdk-admin'

import { AFormCheckboxItem, AButton } from '../atoms'
import { MFormInput } from '../molecules'

export const OUserDetailsInfo = ({
  user,
  onUserDeleted = () => {},
  onUserArchived = () => {},
  points,
  readOnly,
  canArchive = false,
  canDelete = true,
  allowedFields = {},
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [isDeleteSaving, setIsDeleteSaving] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [isArchiveSaving, setisArchiveSaving] = useState(false)
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)
  const [editError, setEditError] = useState()

  const { users, isSuperAdmin } = useStore(state => ({
    users: state.users,
    isSuperAdmin: state.isSuperAdmin,
  }))

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...user },
  })

  const allowedFieldsFull = useMemo(
    () => ({
      active: true,
      firstName: true,
      lastName: true,
      email: true,
      ...allowedFields,
    }),
    [allowedFields]
  )

  const handleEditButton = useCallback(() => {
    setIsEditable(!isEditable)
  }, [isEditable])

  const handleSubmitEdit = useCallback(
    async data => {
      try {
        setEditError(null)
        setIsEditSaving(true)
        const hasEmailChanged = data?.email !== user.email

        if (hasEmailChanged && users.find(user => user?.email === data.email)) {
          throw new Error('User with this email already exists')
        }
        console.log('data', data)
        await updateUser({
          id: data?.id,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          active: data?.active,
        })

        setIsEditable(false)
      } catch (err) {
        setEditError(err?.message)
      }
      setIsEditSaving(false)
    },
    [user?.email, users]
  )

  const handleDeleteUser = useCallback(async () => {
    setIsDeleteSaving(true)

    await deleteUser(user?.id)
    onUserDeleted(user?.id)

    setIsDeleteSaving(false)
  }, [user?.id, onUserDeleted])

  const handleArchiveUser = useCallback(async () => {
    setisArchiveSaving(true)

    await archiveUser(user?.id)
    onUserArchived(user?.id)

    setisArchiveSaving(false)
    setShowArchiveConfirmation(false)
  }, [user?.id, onUserArchived])

  return (
    <div className="react-web--admin__container">
      <form onSubmit={handleSubmit(handleSubmitEdit)}>
        <div className="bg-white shadow sm:rounded-lg mb-24">
          <div className="sm:flex justify-between px-4 py-6 sm:px-6">
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-900">User Information</h3>

              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Personal details and contact information.
              </p>

              {allowedFieldsFull.active && (
                <div className="w-auto inline-block mt-2">
                  {isEditable ? (
                    <Controller
                      name="active"
                      register={register('active')}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => {
                        return (
                          <AFormCheckboxItem
                            id="active"
                            name={'Active'}
                            checked={value}
                            onChange={onChange}
                            reversed
                          />
                        )
                      }}
                    />
                  ) : (
                    <dd className="text-sm text-gray-500 flex flex-row items-center justify-between ">
                      <div
                        className={classnames('w-3 h-3 mr-3 rounded-full', {
                          'bg-active': user?.active,
                          'bg-red-400': !user?.active,
                        })}
                      />
                      <span>{user?.active ? 'Active' : 'Deactivated'}</span>
                    </dd>
                  )}
                </div>
              )}
            </div>
            {!readOnly && (
              <div>
                <AButton onClick={handleEditButton} className="mt-2 sm:mt-0">
                  {!isEditable ? 'Edit' : 'Cancel Editting'}
                </AButton>
              </div>
            )}
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {allowedFieldsFull.firstName && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">First name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="firstName"
                      type="text"
                      name="firstName"
                      errorMessage={errors?.firstName?.message}
                      className="w-full appearance-none block px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('firstName', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {user?.firstName}
                    </dd>
                  )}
                </div>
              )}
              {allowedFieldsFull.lastName && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Last name</dt>

                  {isEditable ? (
                    <MFormInput
                      id="lastName"
                      type="text"
                      name="lastName"
                      errorMessage={errors?.lastName?.message}
                      className="w-full appearance-none block px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('lastName', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {user?.lastName}
                    </dd>
                  )}
                </div>
              )}
              {allowedFieldsFull.email && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Email address</dt>

                  {isEditable ? (
                    <MFormInput
                      id="email"
                      type="email"
                      name="email"
                      errorMessage={errors?.email?.message}
                      className="w-full appearance-none block px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-gray-300 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      {...register('email', {
                        required: 'Invalid email address',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                      })}
                    />
                  ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {user?.email}
                    </dd>
                  )}
                </div>
              )}

              {!!user?.organization?.name && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Organization</dt>

                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {user?.organization?.name}
                  </dd>
                </div>
              )}

              {!!user?.team?.name && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Team</dt>

                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {user?.team?.name}
                  </dd>
                </div>
              )}

              {points ? (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Points</dt>

                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {points}
                  </dd>
                </div>
              ) : null}
            </dl>

            <div className="flex flex-row items-center justify-end">
              {isEditable && (
                <div className="px-4">
                  <div className="flex justify-end">
                    <div className="flex items-center">
                      {isEditable && canArchive && (
                        <div className="flex flex-row justify-end p-4">
                          {showArchiveConfirmation ? (
                            <div className="flex items-center gap-x-4">
                              <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                                Are you sure?
                              </p>
                              <AButton
                                secondary
                                onClick={() => {
                                  setShowArchiveConfirmation(false)
                                }}
                              >
                                Cancel
                              </AButton>
                              <AButton
                                warning
                                onClick={handleArchiveUser}
                                isLoading={isArchiveSaving}
                                className="whitespace-nowrap"
                                loadingText="Archiving..."
                              >
                                Yes, archive user
                              </AButton>
                            </div>
                          ) : (
                            <AButton
                              secondary
                              onClick={() => setShowArchiveConfirmation(true)}
                              className="whitespace-nowrap"
                            >
                              Archive user
                            </AButton>
                          )}
                        </div>
                      )}

                      {isEditable && canDelete && isSuperAdmin && (
                        <div className="flex flex-row justify-end p-4">
                          {showDeleteConfirmation ? (
                            <div className="flex items-center gap-x-4">
                              <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                                Are you sure?
                              </p>
                              <AButton
                                secondary
                                onClick={() => {
                                  setShowDeleteConfirmation(false)
                                }}
                              >
                                Cancel
                              </AButton>
                              <AButton
                                warning
                                onClick={handleDeleteUser}
                                isLoading={isDeleteSaving}
                                className="whitespace-nowrap"
                              >
                                Yes, delete user
                              </AButton>
                            </div>
                          ) : (
                            <AButton
                              warning
                              onClick={() => setShowDeleteConfirmation(true)}
                              className="whitespace-nowrap"
                            >
                              Delete user
                            </AButton>
                          )}
                        </div>
                      )}
                      <AButton type="submit" isLoading={isEditSaving} loadingText="Saving...">
                        Save changes
                      </AButton>
                    </div>
                  </div>
                  {!!editError && (
                    <span className="block text-right text-sm leading-5 font-medium rounded-md text-red-500 mr-4">
                      {editError}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

OUserDetailsInfo.propTypes = {
  allowedFields: PropTypes.shape({
    active: PropTypes.bool,
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    email: PropTypes.bool,
  }),
}
