import { doc, deleteDoc } from 'firebase/firestore'

import { getAxiosInstance } from './axios.js'
import { useStore } from '../stores/createStore.js'
import { arrayToObj } from '../utils/helpers.js'

import {
  auth,
  COLLECTIONS,
  firestoreDb,
  createUserFunction,
  doesUserExistFBFunction,
  doesAdminUserExistsFbFunction,
  retrieveTeamLeaderboards,
  updateUserFunction,
  archiveUserFunction,
} from './firebase'

const getRoleAcl = role => {
  const names = {
    super_admin: {
      key: 'super_admin',
      label: 'Super Admin',
    },
    admin: {
      key: 'admin',
      label: 'Admin',
    },
    teacher_admin: {
      key: 'teacher_admin',
      label: 'Teacher Admin',
    },
  }

  return names[role] || names.admin
}

const checkIfAdminRoleAllowed = role => {
  if (role !== 'super_admin' && role !== 'admin' && role !== 'teacher_admin') {
    return false
  }

  return true
}

export const useUser = userId => {
  const { users, isUsersLoading, teams, organizations } = useStore(state => ({
    users: state.users,
    isUsersLoading: state.isUsersLoading,
    teams: state.teams,
    organizations: state.organizations,
  }))

  const user = users.find(user => user.id === userId)
  const team = user && teams.find(team => team.id === user.teamId)
  const organization =
    user && organizations.find(organization => organization.id === user.organizationId)

  return [
    user && { id: userId, team, organization, active: user.active !== false, ...user },
    isUsersLoading,
  ]
}

export const useUsers = () => {
  const { usersRaw, isUsersLoading, teams, organizations } = useStore(state => ({
    usersRaw: state.users,
    isUsersLoading: state.isUsersLoading,
    teams: state.teams,
    organizations: state.organizations,
  }))

  const users = usersRaw
    ? usersRaw.map(data => {
        const detailsTmp = data.email

        const team = teams.find(team => team.id === data.teamId)
        const organization = organizations.find(
          organization => organization.id === data.organizationId
        )

        return { ...data, team, organization, active: data.active !== false, details: detailsTmp }
      })
    : null

  return [users, isUsersLoading]
}

export const useArchivedUsers = () => {
  const { archivedUsersRaw, isUsersLoading, teams, organizations } = useStore(state => ({
    archivedUsersRaw: state.archivedUsers,
    isArchivedUsersLoading: state.isArchivedUsersLoading,
    teams: state.teams,
    organizations: state.organizations,
  }))

  const users = archivedUsersRaw
    ? archivedUsersRaw.map(data => {
        const detailsTmp = data.email

        const team = teams.find(team => team.id === data.teamId)
        const organization = organizations.find(
          organization => organization.id === data.organizationId
        )

        return { ...data, team, organization, active: data.active !== false, details: detailsTmp }
      })
    : null

  return [users, isArchivedUsersLoading]
}

export const useAdminUser = adminUserId => {
  const { adminUsers, isAdminUsersLoading, acl } = useStore(state => ({
    adminUsers: state.adminUsers,
    isAdminUsersLoading: state.isAdminUsersLoading,
    acl: state.acl,
  }))

  let adminUser = adminUsers.find(adminUser => adminUser.id === adminUserId)

  if (adminUser) {
    const aclObj = arrayToObj(acl)

    const adminUserAclDoc = aclObj?.[adminUserId]

    adminUser.acl = aclObj?.[adminUserId]?.acl

    adminUser.isSuperAdmin = adminUserAclDoc?.role === 'super_admin'

    adminUser.active = adminUser.active !== false

    adminUser.role = getRoleAcl(adminUserAclDoc?.role)
  }

  return [adminUser, isAdminUsersLoading]
}

export const useAdminUsers = () => {
  const { adminUsersRaw, isAdminUsersLoading, acl, teams, organizations } = useStore(state => ({
    adminUsersRaw: state.adminUsers,
    isAdminUsersLoading: state.isAdminUsersLoading,
    acl: state.acl,
    teams: state.teams,
    organizations: state.organizations,
  }))

  const users = adminUsersRaw
    ? adminUsersRaw.map(data => {
        const detailsTmp = data.email

        const aclObj = arrayToObj(acl)

        const adminUserAclDoc = aclObj?.[data?.id]

        const isSuperAdmin = adminUserAclDoc?.role === 'super_admin'

        const role = getRoleAcl(adminUserAclDoc?.role)

        let adminAclOrganizations = []
        let adminTeams = []

        if (role.key === 'admin' || role.key === 'teacher_admin') {
          const adminAcl = acl?.find(_acl => _acl.id === data.id)

          const aclArray = Array.isArray(adminAcl?.acl) ? adminAcl?.acl : Object.keys(adminAcl?.acl || {})

          aclArray.forEach(orgId => {
            const organizationFull = organizations.find(org => org.id === orgId)

            if (organizationFull) {
              adminAclOrganizations.push(organizationFull)
            }
          })
        }

        if (role.key === 'admin') {
          teams.forEach(team => {
            const isTeamInOrganization = !!adminAclOrganizations.find(
              org => org.id === team.organizationId
            )

            if (isTeamInOrganization) {
              adminTeams.push(team)
            }
          })
        }

        if (role.key === 'teacher_admin') {
          const adminAcl = acl?.find(_acl => _acl.id === data.id)

          Object.values(adminAcl?.acl || {})
            ?.map(teamsObject => Object.keys(teamsObject || {}))
            ?.flat()
            ?.forEach(teamId => {
              const team = teams.find(team => team.id === teamId)

              if (team) {
                adminTeams.push(team)
              }
            })
        }

        return {
          ...data,
          active: data.active !== false,
          details: detailsTmp,
          role: getRoleAcl(adminUserAclDoc?.role),
          teams: adminTeams,
          organizations: adminAclOrganizations,
        }
      })
    : null

  return [users, isAdminUsersLoading]
}

export const addUser = async user => {
  if (!user) throw new Error('No user specified')

  const { organizationId, teamId, availableTopics, onboardingMission, ...userData } = user

  if (!user?.email) {
    throw new Error('user must have an email')
  }

  if (!organizationId) {
    throw new Error('user must have organizationId')
  }

  if (!teamId) {
    throw new Error('user must have teamId')
  }

  const email = user.email.toLowerCase().replace(/ /g, '')

  const result = await doesUserExistFBFunction({ email })
  const { userExists } = result.data

  if (!userExists) {
    const dataToSave = {
      user: {
        ...userData,
        email,
        didJoin: false,
        createdBy: auth.currentUser.uid,
        organizationId,
        teamId,
        availableTopics: availableTopics
          .map(topic => ({
            ...topic,
            missionsAndSeries: topic?.missionsAndSeries?.length
              ? topic.missionsAndSeries.filter(tms => tms.isAssigned !== false)
              : null,
          }))
          .filter(topic => topic.missionsAndSeries?.length),
        onboardingMission: onboardingMission?.id,
      },
      acl: {
        role: 'user',
        organizationId,
        teamId,
      },
    }

    const result = await createUserFunction(dataToSave)

    return result.data
  } else {
    throw new Error('User with this email already exists')
  }
}

export const addUserWithoutContent = async user => {
  if (!user) throw new Error('No user specified')

  const { organizationId, teamId, onboardingMission, ...userData } = user

  if (!user?.email) {
    throw new Error('user must have an email')
  }

  if (!organizationId) {
    throw new Error('user must have organizationId')
  }

  if (!teamId) {
    throw new Error('user must have teamId')
  }

  const email = user.email.toLowerCase().replace(/ /g, '')

  const result = await doesUserExistFBFunction({ email })
  const { userExists } = result.data

  if (!userExists) {
    const dataToSave = {
      user: {
        ...userData,
        email,
        didJoin: false,
        createdBy: auth.currentUser.uid,
        organizationId,
        teamId,
        onboardingMission: onboardingMission?.id,
      },
      acl: {
        role: 'user',
        organizationId,
        teamId,
      },
    }

    const result = await createUserFunction(dataToSave)

    return result.data
  } else {
    throw new Error('User with this email already exists')
  }
}

export const addAdminUser = async adminUser => {
  if (!adminUser) throw new Error('No Admin user specified')
  if (!adminUser.email) {
    throw new Error('Admin user must have an email')
  }
  if (!adminUser.isSuperAdmin && !Object.keys(adminUser.acl || {}).length) {
    throw new Error('Organization Access must have at least one organization selected')
  }

  if (adminUser.role && checkIfAdminRoleAllowed(adminUser.role)) {
    throw new Error(`Admin user ${adminUser.role} role is not allowed`)
  }

  const email = adminUser.email.toLowerCase().replace(/ /g, '')

  const result = await doesAdminUserExistsFbFunction({ email })
  const { userExists } = result.data

  if (!userExists) {
    const { acl, adminRole, isSuperAdmin, ...adminUserData } = adminUser

    const aclToRequest = {
      role: isSuperAdmin ? 'super_admin' : adminRole || 'admin',
      acl,
    }

    const result = await createUserFunction({
      user: {
        ...adminUserData,
        email,
        isSuperAdmin: isSuperAdmin || adminRole === 'super_admin',
        didJoin: false,
        createdBy: auth.currentUser.uid,
      },
      acl: aclToRequest,
    })

    return result.data
  } else {
    throw new Error('Admin user with this email already exists')
  }
}

export const updateUser = async user => {
  if (!user) throw new Error('No user specified')
  if (!user.email) {
    throw new Error('user must have an email')
  }

  const result = await doesUserExistFBFunction({ id: user.id })
  const { userExists } = result.data

  if (userExists) {
    const { id, ...userData } = user
    const email = user.email.toLowerCase().replace(/ /g, '')

    const result = await updateUserFunction({
      user: {
        ...userData,
        email,
        id,
      },
      acl: {
        role: 'user',
      },
    })

    return result.data
  } else {
    throw new Error('User does not exist')
  }
}

export const archiveUser = async userId => {
  if (!userId) throw new Error('No userId specified')

  await archiveUserFunction({ userId })
}

export const deleteUser = async userId => {
  if (!userId) throw new Error('No userId specified')

  await deleteDoc(doc(firestoreDb, COLLECTIONS.users, userId))
}

export const deleteAdminUser = async adminUserId => {
  if (!adminUserId) throw new Error('No adminUserId specified')

  await deleteDoc(doc(firestoreDb, COLLECTIONS.adminUsers, adminUserId))
}

export const updateAdminUser = async adminUser => {
  if (!adminUser) throw new Error('No Admin user specified')
  if (!adminUser.email) {
    throw new Error('Admin user must have an email')
  }

  const result = await doesAdminUserExistsFbFunction({ id: adminUser.id })
  const { userExists } = result.data

  if (userExists) {
    const { acl, isSuperAdmin, adminRole, ...adminUserData } = adminUser
    const email = adminUser.email.toLowerCase().replace(/ /g, '')

    const role = isSuperAdmin ? 'super_admin' : adminRole || 'admin'

    const aclToRequest = {
      role: isSuperAdmin ? 'super_admin' : adminRole || 'admin',
      acl,
    }

    const result = await updateUserFunction({
      user: {
        ...adminUserData,
        email,
        isSuperAdmin,
      },
      acl: aclToRequest,
    })

    return result.data
  } else {
    throw new Error('Admin user does not exist')
  }
}

export const fetchFullUserContent = async userId => {
  const userContentResponse = await getAxiosInstance().get(`/admin/user-assigned-content/${userId}`)
  const userContentData = userContentResponse?.data

  const userProgress = await fetchUserProgress(userId)

  const userMissionsAndSeries = userContentData.missionsAndSeries

  const userTopics = userContentData.topics

  const userOnboardingMission = userContentData.onboardingMission

  const topics = userTopics.map(topic => {
    const topicId = topic.id || topic._id

    let topicData = { ...topic }

    const { missionsAndSeries: topicMissionsAndSeriesProgress, ...progress } =
      userProgress[topicId] || {}

    if (progress) {
      topicData.progress = progress
    }

    return {
      ...topicData,
      missionsAndSeries: topic.missionsAndSeries
        .map(tms => {
          const tmsId = tms.id

          let tmsData = userMissionsAndSeries[tmsId]

          const missionOrSerieProgress = topicMissionsAndSeriesProgress?.[tmsId]

          if (missionOrSerieProgress) {
            tmsData.progress = missionOrSerieProgress
          }

          if (tmsData.missions) {
            tmsData.missions = tmsData.missions
              .map(missionId => {
                let missionData = userMissionsAndSeries[missionId]

                const missionProgress = topicMissionsAndSeriesProgress?.[missionId]

                if (missionProgress) {
                  missionData.progress = missionProgress
                }

                return missionData
              })
              .filter(mission => mission)
          }

          return tmsData
        })
        .filter(tms => tms),
    }
  })

  return { topics, onboardingMission: userOnboardingMission }
}

export const fetchUserProgress = async userId => {
  const userProgressResponse = await getAxiosInstance().get(`/admin/user-progress/${userId}`)
  const userProgressData = userProgressResponse?.data

  return userProgressData
}

export const updateUserContent = async (userId, availableTopics = [], onboardingMissionId) => {
  if (!userId) {
    throw new Error('No userId specified')
  }

  const topicsToEdwin = availableTopics
    .map(topic => ({
      ...topic,
      missionsAndSeries: topic?.missionsAndSeries?.length
        ? topic.missionsAndSeries.filter(tms => tms.isAssigned !== false)
        : null,
    }))
    .filter(topic => topic.missionsAndSeries?.length)

  await getAxiosInstance().post('/admin/users-assign-content', {
    users: [userId],
    topics: topicsToEdwin,
    onboardingMissionId: onboardingMissionId,
  })
}

export const fetchUserPoints = async userId => {
  const result = await retrieveTeamLeaderboards({
    userId,
  })

  return result?.data?.[0]?.points
}
