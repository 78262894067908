const CONFIG = {
  firebase: {
    apiKey: "AIzaSyC5kwiMFI8ZvUm5EWnaMxzBRo_2ckf3pn8",
    authDomain: "max-sports-health.firebaseapp.com",
    projectId: "max-sports-health",
    storageBucket: "max-sports-health.appspot.com",
    messagingSenderId: "1039181657445",
    appId: "1:1039181657445:web:6707281c9f3db94cb65d8c"
  },
  connectFirebaseEmulators: false,
  edwinCloudApi: 'https://api.edwin.cloud/v1',
  domain: 'https://admin.maxsportshealth.com'
}

export default CONFIG
