import React, { useEffect, useState, useCallback } from 'react'

import OPageWrapper from '@organisms/OPageWrapper'
import { OChatWidget } from '@edwin/react-web-admin'

import { fetchChatCredentials } from '@services/chat'
import useStore from '@store/useStore'
import useChatsStore from '@maxsports/web-app/src/store/useChatStore'

const PChat = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [chatCredentials, setChatCredentials] = useState()
  const { uid } = useStore(state => ({
    uid: state.adminUser?.id,
  }))

  const refreshChatUnreadMessageCount = useChatsStore(state => state.refreshChatUnreadMessageCount)

  const handleSetChatCredentials = useCallback(async () => {
    setIsLoading(true)

    try {
      const credentials = await fetchChatCredentials()
      let data = credentials.data

      setChatCredentials(data)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (uid) {
      handleSetChatCredentials()
    }
  }, [handleSetChatCredentials, uid])

  return (
    <OPageWrapper isLoading={isLoading}>
      <OChatWidget
        chatCredentials={chatCredentials}
        onItemClick={refreshChatUnreadMessageCount}
        onLogout={refreshChatUnreadMessageCount}
      />
    </OPageWrapper>
  )
}

PChat.propTypes = {}

export default PChat
