import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams, useLocation, useNavigate, Route, Routes, Navigate } from 'react-router-dom'

import useStore from '@store/useStore'
import { useUser, fetchUserPoints } from '@services/users'

import { UserIcon, RectangleGroupIcon } from '@heroicons/react/20/solid'

import { MTabs, OUserDetailsInfo, OUserProgress } from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'
import ROUTES, { generateRoute } from '@const/Routes'

const TABS = [
  { id: 'info', name: 'User Details', icon: UserIcon },
  { id: 'progress', name: 'Progress', icon: RectangleGroupIcon },
  // { id: 'calendar', name: 'Calendar', icon: CalendarDaysIcon },
  // { id: 'timeline', name: 'Timeline', icon: QueueListIcon },
  // { id: 'providers-activity', name: 'Providers Activity', icon: CursorArrowRaysIcon },
  // { id: 'questionnaires', name: 'Questionnaires', icon: SquaresPlusIcon },
]

const PUser = ({ withPoints = false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { userId } = params
  const [user = location?.state?.user, isUserLoading] = useUser(userId)
  const [points, setPoints] = useState()
  const [isPointsLoading, setIsPointsLoading] = useState(true)
  const readOnly = useStore(state => state.readOnly)
  const isSuperAdmin = useStore(state => state.isSuperAdmin)

  const isInOrganizationRoute = useMemo(
    () => location?.pathname?.includes('organizations'),
    [location?.pathname]
  )

  const activeTab = useMemo(() => {
    const pathParts = location.pathname.split('/')
    const lastPart = pathParts[pathParts.length - 1]
    if (TABS.find(tab => tab.id === lastPart)) {
      return lastPart
    } else {
      return 'info'
    }
  }, [location?.pathname])

  /**
   * useMemo hook to get the organization and team data from the user and location state.
   * If the user has an organization and team, they will be used.
   * Otherwise, the organization and team data will be taken from the location state.
   * If there is no location state, empty objects will be returned.
   */
  const { organization, team } = useMemo(() => {
    const organization = user?.organization || location?.state?.organization || {}
    const team = user?.team || location?.state?.team || {}

    return { organization, team }
  }, [location?.state, user])

  /**
   * useMemo hook to generate breadcrumbs based on user, organization and team data.
   * If the current location path includes 'organizations', the breadcrumbs will include the organization and team names.
   * Otherwise, the breadcrumbs will only include the user's full name.
   */
  const breadCrumbs = useMemo(() => {
    if (isInOrganizationRoute) {
      return [
        { type: 'home', navigateTo: ROUTES.ROOT },
        {
          name: organization?.name,
          navigateTo: generateRoute(ROUTES.ORGANIZATION, {
            organizationId: organization?.id,
          }),
          state: { organization },
        },
        {
          name: team?.name,
          navigateTo: generateRoute(ROUTES.ORGANIZATION_TEAM, {
            organizationId: organization?.id,
            teamId: team?.id,
          }),
          state: { organization, team },
        },
        { name: `${user?.firstName} ${user?.lastName}`, state: { organization, team, user } },
      ]
    } else {
      return [
        { type: 'home', navigateTo: ROUTES.USERS },
        { name: `${user?.firstName} ${user?.lastName}` },
      ]
    }
  }, [isInOrganizationRoute, user, organization, team])

  const handleUserDeleted = useCallback(() => {
    if (isInOrganizationRoute) {
      return navigate(
        generateRoute(ROUTES.ORGANIZATION_TEAM, {
          organizationId: organization?.id,
          teamId: team?.id,
        })
      )
    }

    navigate(ROUTES.USERS)
  }, [isInOrganizationRoute, navigate, organization?.id, team?.id])

  const handleFetchUserPoints = useCallback(async () => {
    if (user?.id) {
      setIsPointsLoading(true)

      const result = await fetchUserPoints(user.id)

      setPoints(result)
      setIsPointsLoading(false)
    }
  }, [user?.id])

  useEffect(() => {
    handleFetchUserPoints()
  }, [handleFetchUserPoints])

  return (
    <OPageWrapper breadcrumbs={breadCrumbs} isLoading={isUserLoading || isPointsLoading}>
      <MTabs
        tabs={TABS}
        activeTab={activeTab}
        onTabChange={newTab => navigate(newTab.id)}
        className="mb-6 lg:mb-12"
      />

      <Routes>
        <Route
          path="progress"
          element={<OUserProgress user={user} points={withPoints && points} />}
        />
        {/*<Route*/}
        {/*  path="timeline"*/}
        {/*  element={*/}
        {/*    <OUserTimeline*/}
        {/*      user={{*/}
        {/*        ...user,*/}
        {/*        didJoinOn: user?.didJoinOn ? user.didJoinOn.seconds * 1000 : 1677452400000,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="calendar"*/}
        {/*  element={*/}
        {/*    <OUserCalendar*/}
        {/*      user={{*/}
        {/*        ...user,*/}
        {/*        didJoinOn: user?.didJoinOn ? user.didJoinOn.seconds * 1000 : 1677452400000,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route path="providers-activity" element={<OProviderActivity />} />*/}
        {/*<Route path="questionnaires" element={<OQuestionnaires user={user} />} />*/}

        <Route
          path="info"
          element={
            <OUserDetailsInfo
              user={user}
              isLoading={isUserLoading}
              onUserDeleted={handleUserDeleted}
              onUserArchived={handleUserDeleted}
              points={withPoints && points}
              readOnly={readOnly}
              canArchive={isSuperAdmin}
              canDelete={false}
              allowedFields={{ active: false }}
            />
          }
        />
        <Route path="*" element={<Navigate to="info" replace />} />
      </Routes>
    </OPageWrapper>
  )
}

export default PUser
