import React, { useCallback, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { OAdminUser } from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'
import { auth } from '@services/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import useStore from '@store/useStore'

import { useAdminUser } from '@services/users'

import { useOrganizations } from '@services/organizations'
import { updateAdminUser, deleteAdminUser } from '@services/users'

import ROUTES, { generateRoute } from '@const/Routes'

const PAdminUser = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { adminUserId } = params
  const [adminUser = location?.state?.adminUser, isUserLoading] = useAdminUser(adminUserId)
  const [authUser] = useAuthState(auth)

  const [organizations] = useOrganizations()
  const isSameAdmin = useMemo(() => {
    return adminUser.id === authUser.uid
  }, [adminUser?.id, authUser?.uid])
  const { isSuperAdmin, adminUsers, teams, acl } = useStore(state => ({
    adminUsers: state.adminUsers,
    isSuperAdmin: state.isSuperAdmin,
    teams: state.teams,
    acl: state.acl,
  }))

  const adminAcl = useMemo(() => {
    if (!acl?.length || !adminUser?.id) return null

    return acl.find(acl => acl.id === adminUser?.id)?.acl
  }, [acl, adminUser?.id])

  const isLoading = isUserLoading

  const breadcrumbs = [
    { type: 'home', navigateTo: ROUTES.ADMIN_USERS },
    { name: `${adminUser?.firstName} ${adminUser?.lastName}` },
  ]

  const handleAdminUserDeleted = useCallback(() => {
    navigate(generateRoute(ROUTES.ADMIN_USERS))
  }, [navigate])

  return (
    <OPageWrapper breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <OAdminUser
        adminUser={{
          acl: adminUser?.acl,
          active: adminUser?.active,
          role: adminUser?.role,
          email: adminUser?.email,
          firstName: adminUser?.firstName,
          lastName: adminUser?.lastName,
          id: adminUser?.id,
          isSuperAdmin: adminUser?.isSuperAdmin,
          timezone: adminUser?.timezone,
        }}
        allowedFields={{ active: false }}
        organizations={organizations}
        teams={teams}
        updateAdminUser={updateAdminUser}
        deleteAdminUser={deleteAdminUser}
        isSameAdmin={isSameAdmin}
        isSuperAdmin={isSuperAdmin}
        adminUsers={adminUsers}
        onAdminUserDeleted={handleAdminUserDeleted}
        availableRoles={['admin', 'super_admin', 'teacher_admin']}
        acl={adminAcl}
      />
    </OPageWrapper>
  )
}

export default PAdminUser
